import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import React, { Component } from "react";
import { newBackend } from "./../../comm/API";
import FormField from "./../../components/formField/formField";
import GenericButton from "./../../components/genericButton/genericButton";
import Table from "./../../components/table/table";
import { InputBMPManufaturaEnum } from "./enum/inputBMPManufatura";
import { InputBMPEEEnum } from "./enum/inputBMPEE";
import InputSelect from '../../components/inputselect/inputselect';
import { TipoInputEnum } from "./enum/tipoInput";
import IndicadoresAlert from "../../components/AlertIndicadores/alertIndicadores";
import { InputSmartFactoryEnum } from "./enum/inputSmartFactory";
import { produtoNacionalBMPEnum } from "./enum/produtoNacionalBMP";

export default class RegistroIndicadores extends Component {
  types = ["label", "label", "inputNumber", "inputNumber", "label", "icon"];


  registroTitlesInput = [
    { id: "0", canOrder: true, label: "Inputs Necessários" },
    { id: "1", canOrder: false, label: "Valor" },
    { id: "2", canOrder: false, label: "Unidade de Medida" },
  ];

  registroIndicadoresInputTitle = [
    { id: "0", canOrder: true, label: "Nome do indicador" },
    { id: "1", canOrder: false, label: "Resultado" },
    { id: "3", canOrder: false, label: "Fórmula" },
  ];

  recebeSmartFactory = [
    { id: 1, label: 'Não', value: '0' },
    { id: 2, label: 'Sim', value: '1' },
  ];


  registroIndicadoresInputTitleAvaliacao = this.registroIndicadoresInputTitle.concat([{ id: "4", canOrder: false, label: "Parecer DN" }]);
  registroIndicadoresInputTitleAvaliado = [{ id: "4", canOrder: false, label: "Parecer DN" }].concat(this.registroIndicadoresInputTitle);

  parecerDNOptions = {
    conforme: { id: 2, chave: "conforme", descricao: "Conforme" },
    naoConforme: { id: 3, chave: "naoconforme", descricao: "Não Conforme" },
  };

  indicadorReferencialLines = [];
  indicadorReferencialList = [];
  InputLines = [];
  InputList = [];
  atendimentoInputList = [];
  insertIndicadorAtendimentoReferencial = [];
  updateSolicitacaoStatus = [];
  medicoesIndicadores = {};
  isSaving = false;


  constructor(props) {
    super(props);
    this.state = {
      atendimentoCP: JSON.parse(JSON.stringify(this.props.atendimento)),
      inputValues: {},
      isModalOpen: false,
      programaBaseNacional: null,
    };
    this.isDN = this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN";
    this.handleInputChange();
    this.getIndicadoresRefresh();
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getIndicadorReferencialLines = async () => {
    const idProdNacional = this.props.atendimento.produtoRegional.produtoNacional.id;
    const idAtendimento = this.props.atendimento.id;
    try {
      const res = await newBackend.get(`/programa-base-nacional/${idAtendimento}`)
      const programaBaseNacional = res.data;
      this.setState({ programaBaseNacional });


      this.InputList = programaBaseNacional.inputs.sort((a, b) => a.id - b.id);
      this.indicadorReferencialList = programaBaseNacional.indicadores.sort((a, b) => a.id - b.id);

      switch (idProdNacional) {
        case produtoNacionalBMPEnum.manufaturaEnxuta:
        case produtoNacionalBMPEnum.smartFactoryPiloto:
          this.ajustarFormulaDoManufatura();
          break;
        case produtoNacionalBMPEnum.eficienciaEnergetica:
          this.sortEE(this.indicadorReferencialList);
          this.ajustarFormulaDoEE();
          break;
        default:
          break;
      }

      await newBackend.get(`/input-programa-atendimento/${idAtendimento}`)
        .then(resAtdInput => {
          this.atendimentoInputList = resAtdInput.data.sort((i, j) => (i.id > j.id ? 1 : -1));
        })
        .catch(err => { });
    } catch (error) {
      console.log(error);
      this.props.closeLoading();
      this.props.showToast("Erro ao Carregar os indicadores deste Atendimento. Favor entrar em contato com o suporte.", "error");
      this.backToResumo();
    }
    this.indicadorReferencialLines = this.indicadorReferencialList.map((indicador) => this.createLineRegistro(indicador));
  };

  ajustarFormulaDoManufatura = async () => {
    this.indicadorReferencialList.forEach(indicador => {
      switch (indicador.nome) {
        case "Retorno do Programa":
          indicador.nome = "Retorno do Programa (payback)";
          indicador.formula = "((Carga horária STI + 4) x 190 + Investimento de Ações) / Ganho Financeiro Mensal";
          break;
        case "Produtividade Lean":
          indicador.formula = "[(Medição Final - Medição Inicial)/ Medição Inicial ] x 100";
          break;
        default:
          break;
      }
    });
  }

  ajustarFormulaDoEE = async () => {
    this.indicadorReferencialList.forEach(indicador => {
      switch (indicador.nome) {
        case "Redução de Consumo Energético":
          if (indicador.sufixo === "%") {
            indicador.formula = "(Medição inicial de Consumo - Medição final de Consumo) / Medição inicial de Consumo x 100";
            indicador.sufixo = "%"
          } else {
            indicador.formula = "Medição inicial de Consumo - Medição final de Consumo";
          }
          break;
        case "Redução de Custo com Energia":
          indicador.formula = "[(Medição inicial de Consumo - Medição final de Consumo) x valor KWh médio x 1000] + Economia com correção de fator de potência + Economia com readequação da demanda tarifária + Economia com reenquadramento tarifário";
          break;
        case "Retorno do Programa (EE)":
          indicador.nome = "Retorno do Programa (EE) (payback)";
          indicador.formula = "[((Carga horária STI + 4) x 190 + (Investimentos com ações da análise tarifária + Investimentos com usos finais) ) / Redução de Custo com Energia ] x 12";
          break;
        default:
          break;
      }
    });
  }

  sortEE = (indicadorReferencialList) => {
    const ordemIndicadoresEE = [5, 28, 6, 12, 13, 14, 29, 31];


    this.indicadorReferencialList = indicadorReferencialList.sort((a, b) => {
      const idA = a.id;
      const idB = b.id;

      const orderIndexA = ordemIndicadoresEE.indexOf(idA);
      const orderIndexB = ordemIndicadoresEE.indexOf(idB);

      return orderIndexA - orderIndexB;
    });
  }

  sortManufatura = (indicadorReferencialList) => {
    const ordemIndicadoresManufatura = [5, 28, 6, 12, 13, 14, 29, 31];


    this.indicadorReferencialList = indicadorReferencialList.sort((a, b) => {
      const idA = a.id;
      const idB = b.id;

      const orderIndexA = ordemIndicadoresManufatura.indexOf(idA);
      const orderIndexB = ordemIndicadoresManufatura.indexOf(idB);

      return orderIndexA - orderIndexB;
    });
  }

  getIndicadoresRefresh = async (index) => {
    if (this.isDN) {
      let atendimento = this.state.atendimentoCP;
      let indicadores = atendimento.indicadorAtendimentoReferencialList;
      if (this.indicadorReferencialLines.length === 0) {
        indicadores = indicadores.sort((curr, other) => (curr.indicadorReferencial.id > other.indicadorReferencial.id ? 1 : -1));
        await indicadores.forEach((indicador, i) => {
          this.indicadorReferencialLines.push(this.createLineAvaliacao(indicador, i));
        });
      } else {
        this.indicadorReferencialLines[index] = this.createLineAvaliacao(indicadores[index], index);
      }
    } else if (index >= 0) {
      this.indicadorReferencialLines[index] = this.createLineRegistro(this.indicadorReferencialList[index]);
    } else {
      await this.getIndicadorReferencialLines();
    }
    this.setState(this.state);
    this.props.closeLoading();
  };

  handleInputChange(idInputToPrograma, novoValor) {
    if (idInputToPrograma === undefined || novoValor === undefined) {
      return;
    }
  
    const existingItemIndex = this.atendimentoInputList.findIndex(
      item => item.id_programa_base_nacional_to_input_programa === idInputToPrograma && item.id_atendimento === this.props.atendimento.id
    );
  
    let parsedValue;
  
    if (!isNaN(parseFloat(novoValor)) && isFinite(novoValor)) {
      parsedValue = parseFloat(novoValor);
    } else {
      const selectedOption = this.recebeSmartFactory.find(item => item.label === novoValor);
      parsedValue = selectedOption ? selectedOption.value?.toString() : undefined;
    }
  
    
    const valueAsString = parsedValue.toString();
      if (existingItemIndex !== -1) {
        this.atendimentoInputList = this.atendimentoInputList.map(item => {
          if (item.id_programa_base_nacional_to_input_programa === idInputToPrograma && item.id_atendimento === this.props.atendimento.id) {
            const updatedItem = { ...item, valor: valueAsString };
            return updatedItem;
          }
          return item;
        });
      } else {
        const newItem = {
          valor: valueAsString,
          id_programa_base_nacional_to_input_programa: idInputToPrograma,
          id_atendimento: this.props.atendimento.id
        };
        this.atendimentoInputList.push(newItem);
      }
  
      this.indicadorReferencialLines = this.indicadorReferencialList.map((indicador) => this.createLineRegistro(indicador));
      this.findInputByProgramaAndAtendimento(idInputToPrograma);
      if (this._isMounted) {
        this.setState({}, () => {
          this.props.closeLoading();
        });

    }
  }
  

  getIndicadorAtendimentoRefModel = (indicador) => {
    return {
      indicadorReferencial: indicador,
      resultado: "",
      solicitacaoStatus: "",
    };
  };

  getInputTableForInputProgram = (id, placeholder, attr, index, isDisabled, inputValue) => {
    const input = this.InputList.find(input => input.id_programa_base_nacional_to_input_programa === id);
    const labelMappings = {
      2: "Inicial",
      3: "Final",
    };
    const labelText = labelMappings[input.id_tipo_input] || '';

    if (input) {
      let valor = inputValue;
      const atendimentoInput = this.atendimentoInputList.find(item => item.id_programa_base_nacional_to_input_programa === id);
      if (atendimentoInput) {
        valor = atendimentoInput.valor;
      }

      // Condicional para a pergunta do SmartFactory
      if (input.id_tipo_input === TipoInputEnum.SmartFactory) {
        return (
          <div key={`input-${id}-${index}`}>
            <div>
              <InputSelect
                suggestions={this.recebeSmartFactory}
                itemKey={'id'}
                itemLabel={'label'}
                id={'id'}
                getInput={(selectedOption) => this.handleInputChange(id, selectedOption.value)}
                initialSelectedItem={this.recebeSmartFactory.find(item => item.value === valor)}
                error=''
              />
            </div>
          </div>
        );
      } else {
        return (
          <div key={`input-${id}-${index}`}>
            {labelText && <label style={{ color: '#077CDC', fontSize: '13px' }}>{labelText}</label>}
            <FormField
              type={"number"}
              changeValue={(e) => this.handleInputChange(id, e.target.value)}
              id={index}
              subtype={"seiscasas"}
              inputValue={valor}
              title={`${labelText} ${placeholder}`}
              placeholder={`${labelText} ${placeholder}`}
              name={attr}
              min="1"
              maxLength="20"
              disabled={isDisabled}
            />
          </div>
        );
      }
    } else {
      console.error('Invalid input object:', input);
      return null;
    }
  };


  getIconTable = (formula) => {
    let prefixFormula = "-";
    let codigoDN = String(this.props.atendimento.produtoRegional.produtoNacional.codigoDN);

    if (String(codigoDN) === "412158") {
      prefixFormula = "";
    }

    const icon = <img src={"./../../assets/icons/formula-indicador.svg"} alt="" />;
    return (
      <div className={"tooltip-indicadores"}>
        <div>
          <span className={"formula"}>
            <Grid container>
              <Grid item container>
                <Grid item> {icon} </Grid>
                <Grid item>
                  <label className={"icon-label"}> Fórmula do resultado </label> <br />
                </Grid>
              </Grid>
              <Grid item>
                {" "}
                <label>
                  {" "}
                  {prefixFormula} {formula}{" "}
                </label>{" "}
              </Grid>
            </Grid>
          </span>
        </div>
        {icon}
      </div>
    );
  };

  getIconInfoInput = (descricao) => {
    const icon = <img src={"./../../assets/icons/formula-indicador.svg"} alt="" />;
    return (
      <div className={"tooltip-indicadores"}>
        <div>
          <span className={"formula"}>
            <Grid container>
              <Grid item container>
                <Grid item> {icon} </Grid>
                <Grid item>
                  <label className={"icon-label"}> Descrição do Indicador </label> <br />
                </Grid>
              </Grid>
              <Grid item>
                {" "}
                <label>
                  {" "}
                  {descricao}{" "}
                </label>{" "}
              </Grid>
            </Grid>
          </span>
        </div>
        {icon}
      </div>
    );
  };

  checkParecerDN = (indicador, index) => {
    let solicitacaoStatus = indicador.solicitacaoStatus ? indicador.solicitacaoStatus.descricao : "";
    const setIcon = (icon) => <img src={`./../../assets/icons/${icon}.svg`} alt="" />;
    return (
      <Grid item xs={12}>
        <Grid container style={{ marginBottom: "3%" }} direction={"row"}>
          <Grid item className={"radioDisabled"}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="position" name={`radio-group-${index}`} value={solicitacaoStatus} onChange={this.changeInput} row>
                <Grid container direction={"row"}>
                  <Grid item xs={5} className={"tooltip-solicitacao"}>
                    <span className={"tooltip-info avaliar"}> Aprovar </span>
                    <FormControlLabel
                      value={"Conforme"}
                      control={
                        <Radio
                          color="primary"
                          disabled={false}
                          checkedIcon={setIcon("conforme-checked")}
                          icon={setIcon(solicitacaoStatus === "Não Conforme" ? "conforme-gray" : "conforme-unchecked")}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={5} className={"tooltip-solicitacao"}>
                    <span className={"tooltip-info avaliar"}> Reprovar </span>
                    <FormControlLabel
                      value={"Não Conforme"}
                      control={
                        <Radio
                          color="primary"
                          disabled={false}
                          checkedIcon={setIcon("nao-conforme-checked")}
                          icon={setIcon(solicitacaoStatus === "Conforme" ? "nao-conforme-gray" : "nao-conforme-unchecked")}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  Resultado = (ref) => {
    let result = NaN;
    const localeStr = (value) => value.toLocaleString("pt-BR", { minimumFractionDigits: 6, maximumFractionDigits: 6 });
    result = this.calculaFormula(ref, result);

    if (ref.id === 31 && result === '1') {
      return (
        <label>
          <b> {`Sim ${ref.sufixo}`} </b>
        </label>
      );
    } else if (ref.id === 31 && result === '0') {
      return (
        <label>
          <b> {`Não ${ref.sufixo}`} </b>
        </label>
      );
    } else if (result === 'Infinity') {
      return (
        <label>
          <b>Erro no cálculo: Divisão por zero</b>
        </label>
      );
    } else {
      return (
        <label>
          <b> {`${isNaN(result) ? "-" : localeStr(result)} ${ref.sufixo}`} </b>
        </label>
      );
    }
  };


  changeInput = (e) => {
    const index = parseInt(e.target.name.split("-")[2]);
    let atendimento = this.state.atendimentoCP;
    let indicador = atendimento.indicadorAtendimentoReferencialList[index];
    indicador.solicitacaoStatus = e.target.value === "Conforme" ? this.parecerDNOptions.conforme : this.parecerDNOptions.naoConforme;
    atendimento.indicadorAtendimentoReferencialList[index] = indicador;
    this.updateSolicitacaoStatus.push({ id: indicador.id, idsolicitacaostatus: indicador.solicitacaoStatus.id });

    this.setState({ atendimentoCP: atendimento });
    this.getIndicadoresRefresh(index);
  };


  createLine = (indicador) => {
    let referencial = indicador.indicadorReferencial;
    let parecerDNOptions = { naoconforme: "reprovado", conforme: "aprovado" };
    let iconParecer = indicador.solicitacaoStatus ? parecerDNOptions[indicador.solicitacaoStatus.chave] : "nao-avaliado";
    let montar;

    if (this.isDN) {
      montar = [
        <b>{referencial.nome}</b>,
        <b>{`${indicador.resultado} ${referencial.sufixo}`}</b>,
        '',
        this.getIconTable(referencial.formula),
      ];
    } else {
      montar = [
        <b>{referencial.nome}</b>,
        this.Resultado(referencial),
        '',
        this.getIconTable(referencial.formula),
      ];
    }

    let avaliado = (
      <Grid container className={"indicadores-avaliados"}>
        <Grid item>
          {" "}
          <img src={`./../../assets/icons/${iconParecer}.svg`} alt="" />{" "}
        </Grid>
        <Grid item>
          {" "}
          <label> {iconParecer === "nao-avaliado" ? "não avaliado" : iconParecer} </label>{" "}
        </Grid>
      </Grid>
    );
    montar = this.isDN ? montar : montar.concat(avaliado);

    return montar;
  };


  createLineInputProgram = (inputs) => {
    const groupedInputs = {};

    inputs.forEach(input => {
      if (!groupedInputs[input.nome]) {
        groupedInputs[input.nome] = [];
      }
      groupedInputs[input.nome].push(input);
    });

    const lines = [];

    for (const nomeInput in groupedInputs) {
      const inputs = groupedInputs[nomeInput];
      let inputRender;

      if (inputs.length > 1) {
        inputRender = (
          <Grid key={`input-${inputs[0].id_programa_base_nacional_to_input_programa}`} container spacing={2}>
            {inputs.map((input, index) => (
              <Grid item xs={6} key={`input-${input.id_programa_base_nacional_to_input_programa}-${index}`}>
                {this.getInputTableForInputProgram(input.id_programa_base_nacional_to_input_programa, input.placeholder, "valor", index, false, input.valor)}
              </Grid>
            ))}
          </Grid>
        );
      } else {
        inputRender = (
          <Grid key={`input-${inputs[0].id_programa_base_nacional_to_input_programa}`} item xs={12}>
            {this.getInputTableForInputProgram(inputs[0].id_programa_base_nacional_to_input_programa, inputs[0].placeholder, "valor", 0, false, inputs[0].valor)}
          </Grid>
        );
      }

      const montar = [
        <b key={`nome-${inputs[0].id_programa_base_nacional_to_input_programa}`}>{nomeInput}</b>,
        inputRender,
        <b key={`sufixo-${inputs[0].id_programa_base_nacional_to_input_programa}`}>{inputs[0].sufixo}</b>,
      ];

      lines.push(montar);
    }

    return lines;
  };

  createLineAvaliacao = (indicador, index) => {
    let line = this.createLine(indicador);
    line.push(this.checkParecerDN(indicador, index));
    return line;
  };

  createLineRegistro = (indicador) => {
    let sort = (indicadores) => indicadores.sort((i, j) => (i.id > j.id ? 1 : -1));
    let atendimento = this.state.atendimentoCP;
    let indicadores = atendimento.indicadorAtendimentoReferencialList;
    atendimento.indicadorAtendimentoReferencialList = sort(indicadores);
    let indicadorAtdRef = atendimento.indicadorAtendimentoReferencialList.find((obj) => obj.indicadorReferencial.id === indicador.id);
    if (indicadorAtdRef) {
      indicadorAtdRef.indicadorReferencial.formula = indicador.formula;
    } else {
      indicadorAtdRef = this.getIndicadorAtendimentoRefModel(indicador);
      indicadores.push(indicadorAtdRef);
      atendimento.indicadorAtendimentoReferencialList = sort(indicadores);
      this.setState({ atendimentoCP: atendimento });
    }

    return this.createLine(indicadorAtdRef);
  };

  mapearIndicadores = () => {
    const atendimentoIndicadorReferencial = [];

    this.insertIndicadorAtendimentoReferencial.forEach(item => {
      const indicador = this.indicadorReferencialList.find(indicador => indicador.id === item.idindicadorreferencial);

      if (indicador) {
        atendimentoIndicadorReferencial.push({
          indicadorReferencial: indicador,
          resultado: item.resultado
        });
      }
    });

    return atendimentoIndicadorReferencial;
  };

  salvarRegistros = async () => {

    if (this.isSaving) {
      this.props.showToast("Aguarde, o registro já está em processamento.", "error");
      return;
    }

    this.isSaving = true;

    let atendimento = JSON.parse(JSON.stringify(this.state.atendimentoCP));
    atendimento.indicadorAtendimentoReferencialList = this.mapearIndicadores();
    const { programaBaseNacional } = this.state;
    try {
      this.props.showLoading();

      if (!this.isDN) {
        const inputSelectValue = this.getValorAtendimentoInput(InputBMPEEEnum.SmartFactory);
        const participaraDoSmartFactory = programaBaseNacional.inputs.some(objeto => objeto.name === 'Está apta a receber o Smart Factory');
        if (participaraDoSmartFactory && inputSelectValue === undefined) {
          this.props.showToast("Para Concluir o Registro preencha todos os Campos.", "error");
          this.props.closeLoading();
          return;
        }
      }
      const allHaveResultado = this.insertIndicadorAtendimentoReferencial.every(item => item.resultado !== null && item.resultado !== undefined && item.resultado !== Infinity && item.resultado !== "NaN" && item.resultado !== "Infinity");
      if (!allHaveResultado) {
        this.props.showToast("Erro ao registrar os indicadores. Favor preencher todos os campos corretamente.", "error");
        this.props.closeLoading();
        return;
      }
      if (this.isDN) {
        await newBackend.patch(`/indicador-atendimento-referencial/solicitacao-status`, this.updateSolicitacaoStatus);
      } else {
        const indicadoresUnicos = this.insertIndicadorAtendimentoReferencial.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.idatendimento === item.idatendimento && t.idindicadorreferencial === item.idindicadorreferencial
          ))
        );
        await newBackend.post(`/input-programa-atendimento/`, { inputs: this.atendimentoInputList });
        await newBackend.post(`/indicador-atendimento-referencial`, { indicadores: indicadoresUnicos });
      }

      this.setState({ atendimentoCP: atendimento });
      this.props.refreshAtendimento(atendimento);
      this.props.closeLoading();
      this.props.showToast(!this.isDN ? "Indicadores registrados com sucesso!" : "Indicadores avaliados com sucesso!", "success");
      this.backToResumo();

    } catch (error) {
      this.props.closeLoading();
      this.props.showToast("Erro ao registrar os indicadores. Favor entrar em contato com o suporte.", "error");
      this.backToResumo();
    } finally {
      this.isSaving = false;
    }
  };

  backToResumo = () => {
    window.scrollTo(0, 0);
    this.props.openIndicadoresScreen(false);
  };

  findInputByProgramaAndAtendimento(idInputPrograma) {

    const atendimentoInput = this.atendimentoInputList.find(item =>
      item.id_programa_base_nacional_to_input_programa === idInputPrograma && item.id_atendimento === this.props.atendimento.id
    );

    return atendimentoInput || { valor: 0 };
  }

  getValorAtendimentoInput({ nome, id_tipo_input }) {
    const inputs = this.InputList.find(item =>
      item.nome === nome &&
      item.id_tipo_input === id_tipo_input
    );

    if (inputs) {
      const atendimentoInput = this.atendimentoInputList.find(item =>
        item.id_programa_base_nacional_to_input_programa === inputs.id_programa_base_nacional_to_input_programa &&
        item.id_atendimento === this.props.atendimento.id
      );

      if (atendimentoInput) {
        return parseFloat(atendimentoInput.valor);
      }
    }

    return undefined;
  }

  calcularVariacaoPercentualMedicoes(medicaoFinal, medicaoInicial) {
    const variacaoPercentual = 100 * (medicaoFinal - medicaoInicial) / medicaoInicial;
    return variacaoPercentual;
  }

  salvarMedicoes (medicaoFinal, medicaoInicial, idIndicador) {
    this.medicoesIndicadores = {
      ...this.medicoesIndicadores,
      [this.props.atendimento.id]: {
        [idIndicador]: {
          medicaoFinal,
          medicaoInicial,
        }
      }
    }
  }


  calculaFormula(ref, result) {
    const idProdutoNacional = this.props.atendimento.produtoRegional.produtoNacional.id;
    const diffMediInicialFinalEE = Math.abs(this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoInicialConsumo) - this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoFinalConsumo));
    const medicaoInicalEE = this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoInicialConsumo);
    const medicaoFinalEE = this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoFinalConsumo);
    const reducaoCustoEnergetico = Math.abs(((diffMediInicialFinalEE * this.getValorAtendimentoInput(InputBMPEEEnum.ValorKWhMedio)) * 1000) + (this.getValorAtendimentoInput(InputBMPEEEnum.EconomiaCorrecaoFatorPotencia) + this.getValorAtendimentoInput(InputBMPEEEnum.EconomiaReadequacaoDemandaContratada) + this.getValorAtendimentoInput(InputBMPEEEnum.EconomiaReequadramentoTarifario)));
    const porteEmpresa = this.props.atendimento.numeroDeProducaoEstimada;

    const operacoes = {
      '870': {
        5: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 5);
          return Math.abs((diffMediInicialFinalEE / this.getValorAtendimentoInput(InputBMPEEEnum.MedicaoInicialConsumo)) * 100);
        },
        28: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 28);
          return diffMediInicialFinalEE;
        },
        6: () => reducaoCustoEnergetico,
        12: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 12);
          return Math.abs(diffMediInicialFinalEE * 0.0426);
        },
        13: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 13);
          return Math.abs(diffMediInicialFinalEE / 4169.76);
        },
        14: () => {
          this.salvarMedicoes(medicaoFinalEE, medicaoInicalEE, 14);
          return Math.abs(diffMediInicialFinalEE / 1.98);
        },
        29: () => Math.abs((((porteEmpresa + 4) * 190 + (this.getValorAtendimentoInput(InputBMPEEEnum.InvestimentoAcaoAnaliseTarifaria) + this.getValorAtendimentoInput(InputBMPEEEnum.InvestimentoUsosFinais))) / reducaoCustoEnergetico) * 12),
        31: () => this.getValorAtendimentoInput(InputBMPEEEnum.SmartFactory),
      },
      '869': {
        4: () => Math.abs((((porteEmpresa + 4) * 190) + this.getValorAtendimentoInput(InputBMPManufaturaEnum.InvestimentoDeAcoes)) / this.getValorAtendimentoInput(InputBMPManufaturaEnum.GanhoFinanceiro)),
        1: () => {
          const produtividadeInicial = Math.abs((this.getValorAtendimentoInput(InputBMPManufaturaEnum.QuantidadeProduzidaInicial) / this.getValorAtendimentoInput(InputBMPManufaturaEnum.QuantidadeHorasTurnoInicial)) / this.getValorAtendimentoInput(InputBMPManufaturaEnum.NumeroOperadoresTurnoInicial));
          const produtividadeFinal = Math.abs((this.getValorAtendimentoInput(InputBMPManufaturaEnum.QuantidadeProduzidaFinal) / this.getValorAtendimentoInput(InputBMPManufaturaEnum.QuantidadeHorasTurnoFinal)) / this.getValorAtendimentoInput(InputBMPManufaturaEnum.NumeroOperadoresTurnoFinal));
          this.salvarMedicoes(produtividadeFinal, produtividadeInicial, 1);
          return (100 * (produtividadeFinal - produtividadeInicial) / produtividadeInicial);
        },
        30: () => Math.abs(this.getValorAtendimentoInput(InputBMPManufaturaEnum.GanhoFinanceiro)),
        31: () => this.getValorAtendimentoInput(InputBMPEEEnum.SmartFactory),
      },
      '873': {
        1: () => {
          const produtividadeInicial = Math.abs((this.getValorAtendimentoInput(InputSmartFactoryEnum.QuantidadeProduzidaInicial) / this.getValorAtendimentoInput(InputSmartFactoryEnum.QuantidadeHorasTurnoInicial)) * this.getValorAtendimentoInput(InputSmartFactoryEnum.NumeroOperadoresTurnoInicial));
          const produtividadeFinal = Math.abs((this.getValorAtendimentoInput(InputSmartFactoryEnum.QuantidadeProduzidaFinal) / this.getValorAtendimentoInput(InputSmartFactoryEnum.QuantidadeHorasTurnoFinal)) * this.getValorAtendimentoInput(InputSmartFactoryEnum.NumeroOperadoresTurnoFinal));
          const result = (100 * (produtividadeFinal - produtividadeInicial) / produtividadeInicial);
          this.salvarMedicoes(produtividadeFinal, produtividadeInicial, 1);
          return isNaN(result) ? 0 : result;
        },
        15: () => {
          const result = this.calcularVariacaoPercentualMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.QualidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.QualidadeOEEInicial));
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.QualidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.QualidadeOEEInicial), 15);
          return isNaN(result) ? 0 : result;
        },
        8: () => {
          const result = this.calcularVariacaoPercentualMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.OEEFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.OEEInicial));
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.OEEFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.OEEInicial), 8);
          return isNaN(result) ? 0 : result;
        },
        3: () => {
          const result = this.calcularVariacaoPercentualMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.ReducaoLeanFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.ReducaoLeanInicial));
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.ReducaoLeanFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.ReducaoLeanInicial), 3);
          return isNaN(result) ? 0 : result;
        },
        9: () => {
          const result = this.calcularVariacaoPercentualMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.DisponibilidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.DisponibilidadeOEEInicial));
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.DisponibilidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.DisponibilidadeOEEInicial), 9);
          return isNaN(result) ? 0 : result;
        },
        10: () => {
          const result = this.calcularVariacaoPercentualMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.DisponibilidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.DisponibilidadeOEEInicial));
          this.salvarMedicoes(this.getValorAtendimentoInput(InputSmartFactoryEnum.DisponibilidadeOEEFinal), this.getValorAtendimentoInput(InputSmartFactoryEnum.DisponibilidadeOEEInicial), 10);
          return isNaN(result) ? 0 : result;
        },
      },

    };

    const operacao = operacoes[idProdutoNacional] && operacoes[idProdutoNacional][ref.id];

    if (operacao) {
      result = operacao();
      // Define 6 casas decimais para quando o numero não for inteiro
      if (ref.id !== 31) { result = Number.isInteger(result) ? result : parseFloat(result.toFixed(6)); }
      this.inserirOuAtualizarResultado(ref.id, result?.toString());
    }
    return result?.toString();
  }

  inserirOuAtualizarResultado(idIndicador, resultado) {
    const existingItemIndex = this.insertIndicadorAtendimentoReferencial.findIndex((item) => item.idindicadorreferencial === idIndicador);
    const atendimentoId = this.props.atendimento.id;
    const medicoesAtendimento = this.medicoesIndicadores[atendimentoId] || {};
    const medicaoIndicador = medicoesAtendimento[idIndicador] || {};
    const medicaoInicial = parseFloat(medicaoIndicador.medicaoInicial);
    const medicaoFinal = parseFloat(medicaoIndicador.medicaoFinal);

    if (existingItemIndex !== -1) {
      this.insertIndicadorAtendimentoReferencial[existingItemIndex] = {
        ...this.insertIndicadorAtendimentoReferencial[existingItemIndex],
        resultado,
        medicaofinal: medicaoFinal ?? null,
        medicaoinicial: medicaoInicial ?? null,
      }
    } else {
      this.insertIndicadorAtendimentoReferencial.push({
        idatendimento: atendimentoId,
        idindicadorreferencial: idIndicador,
        resultado: resultado,
        medicaofinal: medicaoFinal ?? null,
        medicaoinicial: medicaoInicial ?? null,
      });
    }
  }

  render() {
    const icon = (img) => <img className={"tooltipIndicadores"} src={`./../../assets/icons/${img}.svg`} alt="" />;
    const action = this.isDN ? "Avaliar" : "Registrar";
    const inputLinesRender = this.createLineInputProgram(this.InputList);
    const { programaBaseNacional } = this.state;


    return (
      <Grid container>
        {programaBaseNacional && (
          <IndicadoresAlert programa={programaBaseNacional} />
        )}
        <Grid item xs={12}>
          <Grid container direction={"row"} className={"registar-anexo"} spacing={2}>
            <Grid item className={"lbl-arrow"}>
              {" "}
              <label className={"left"}> Resumo do Atendimento </label>{" "}
            </Grid>
            <Grid item className={"arrow-indicadores"}>
              {" "}
              {icon("arrow-registro-indicadores")}{" "}
            </Grid>
            <Grid item className={"lbl-arrow"}>
              {" "}
              <label className={"right"}>{action} Indicadores</label>{" "}
            </Grid>
          </Grid>
        </Grid>
        {inputLinesRender.length > 0 && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Table
              cellWidth={"15%"}
              lines={inputLinesRender}
              titles={this.registroTitlesInput}
              types={this.types}
              initialOrder={""}
              pagination={false}
            ></Table>
          </Grid>
        )}
        {this.indicadorReferencialLines.length > 0 && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Table
              cellWidth={"15%"}
              lines={this.indicadorReferencialLines}
              titles={this.isDN ? this.registroIndicadoresInputTitleAvaliacao : this.registroIndicadoresInputTitleAvaliado}
              types={this.types}
              initialOrder={""}
              pagination={false}
            ></Table>
          </Grid>
        )}
        {this.indicadorReferencialLines.length > 0 && (
          <Grid item container xs={12} justify={"center"} style={{ marginTop: "2%" }}>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton
                color={"darkBlue-outline"}
                label={"Cancelar"}
                subClass={"basic-button"}
                click={() => {
                  this.backToResumo();
                }}
              />
            </Grid>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton color={"darkBlue"} label={action} subClass={"basic-button"} click={this.salvarRegistros} disabled={this.isSaving} />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
