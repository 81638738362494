export const PorteClienteEnum = {
  MICRO_EMPRESA: 1,
  PEQUENO_PORTE: 2,
  MEDIO_PORTE: 3,
  GRANDE_PORTE: 4,
  MICROEMPREENDEDOR_INDIVIDUAL: 5,
  DEMAIS: 6,
}

export const porteClienteReceitaMapping = {
  'MICRO EMPRESA': PorteClienteEnum.MICRO_EMPRESA,
  'EMPRESA DE PEQUENO PORTE': PorteClienteEnum.PEQUENO_PORTE,
  'DEMAIS': PorteClienteEnum.DEMAIS,
};