import { Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import axios from 'axios';
import React, { Component } from 'react';
import API, { newBackend } from '../../comm/API';
import Masks from '../../comm/masks';
import FormField from '../../components/formField/formField';
import GenericButton from '../../components/genericButton/genericButton';
import InputMany from '../../components/inputMany/inputMany';
import InputSelect from '../../components/inputselect/inputselect';
import Page from '../../components/page/page';
import Stepper from '../../components/stepper/stepper';
import './clienteNovo.scss';
import { PaisList } from './paisList';
import AlertTipoCnpj from '../../components/AlertTipoCnpjCliente/alertTipoCnpj';

export default class Colaborador extends Component {

    titles = ['Informações gerais', 'Endereço', 'Contatos', 'Revisão'];
    titlesNew = ['Nova organização/pessoa', 'Endereço', 'Contatos', 'Revisão'];
    cliente = [];
    errors = {
        nome: '', tipoPessoa: '', tipoCnpj: '', cpfcnpj: '', pais: '', razaoSocial: '', cep: '', bairro: '',
        cnae: '', numeroDeFuncionarios: '', faturamento: '', isAtivo: '', municipio: '', logradouro: '',
        contatonome: '', contatoemail: '', contatotelefone: '', contatosetor: '',
    };
    tipoClienteList = [
        { id: 0, type: 'tipoPessoa', label: 'PESSOA FÍSICA' },
        { id: 1, type: 'tipoPessoa', label: 'PESSOA JURÍDICA' },
        { id: 2, type: 'tipoPessoa', label: 'PESSOA FÍSICA ESTRANGEIRA' },
        { id: 3, type: 'tipoPessoa', label: 'PESSOA JURÍDICA ESTRANGEIRA' }];
    tipoPorteFuncionarios = [
        { id: 1, type: 'porteRais', label: 'ATÉ 04 COLABORADORES', value: 2 },
        { id: 5, type: 'porteRais', label: 'DE 05 A 19 COLABORADORES', value: 10 },
        { id: 2, type: 'porteRais', label: 'DE 20 A 99 COLABORADORES', value: 50 },
        { id: 3, type: 'porteRais', label: 'DE 100 A 499 COLABORADORES', value: 250 },
        { id: 4, type: 'porteRais', label: 'ACIMA DE 500 COLABORADORES', value: 1000 }];
    tipoPorteFaturamento = [
        { id: 1, type: 'porteFaturamento', label: 'ATÉ R$ 360 MIL/ANO', value: 150000 },
        { id: 2, type: 'porteFaturamento', label: 'DE R$ 360 MIL/ANO ATÉ R$ 4,8 MILHÕES/ANO', value: 2400000 },
        { id: 3, type: 'porteFaturamento', label: 'DE R$ 4,8 MILHÕES/ANO ATÉ R$ 300 MILHÕES/ANO', value: 150000000 },
        { id: 4, type: 'porteFaturamento', label: 'ACIMA DE R$ 300 MILHÕES/ANO', value: 500000000 }];
    tipoCnpjList = [
        { id: 1, type: 'tipoCnpj', label: 'Agência de Fomento' },
        { id: 2, type: 'tipoCnpj', label: 'Instituição de Ciência e Tecnologia' },
        { id: 3, type: 'tipoCnpj', label: 'Mercado/empresa' },
        { id: 4, type: 'tipoCnpj', label: 'Sistema Indústria' }];
    ativoList = [{ type: 'isAtivo', label: 'ATIVO', valor: true }, { type: 'isAtivo', label: 'INATIVO', valor: false }];
    pageTitle = 'Nova Organização/Pessoa';
    isDN = true;
    receitaApiURL = '';
    cnaeSecundarioList = [];
    alertaTipoCnpj = false;

    constructor(props) {
        super(props);
        this.cliente = this.clienteModel(true);
        var endereco = this.enderecoModel();
        var contato = this.contatoModel();
        this.state = {
            usuarioConectado: props.usuarioConectado, endereco: endereco, municipio: '',
            unidadeFederativa: '', contatoBoxList: [], contato: contato, validateEndereco: false, validateContato: false
        };
        this.getReceitaApiURL();
        if (props.asModal === true) this.getFunctions(props.showToast, props.showLoading, props.closeLoading);
        if (props.idCliente != null && !isNaN(props.idCliente)) {
            this.pageTitle = 'Editar Cliente';
            this.isDN = props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN' ? true : false;
            newBackend.get(`/cliente/${props.idCliente}`).then(async res => {
                if (res.data.contatos && Array.isArray(res.data.contatos) && res.data.contatos.length > 0) {
                    res.data.contatos = res.data.contatos.map(({ audittimestamp, audituser, idunidade, idcliente, ...rest }) => rest);
                }

                this.cliente = {
                    id: res.data.id ?? null,
                    cpfcnpj: res.data.cpfcnpj ?? null,
                    nome: res.data.nome,
                    inscricaoEstadual: res.data.inscricaoestadual ?? null,
                    razaoSocial: res.data.razaosocial ?? null,
                    tipoCnpj: { id: res.data.idtipocnpj } ?? null,
                    tipoPessoa: res.data.tipopessoa,
                    documentoEstrangeiro: res.data.documentoestrangeiro ?? null,
                    isAtivo: res.data.isativo,
                    porteFaturamento: res.data.porte_cliente_faturamento ? res.data.porte_cliente_faturamento.id : null,
                    porteRais: res.data.porte_cliente_rais ? res.data.porte_cliente_rais.id : null,
                    contatoList: res.data.contatos,
                    pais: res.data.pais,
                }

                if (this.cliente !== null && this.cliente.tipoCnpj.id === null) {
                    this.alertaTipoCnpj = true;
                }

                this.cnaeSecundarioList = this.cliente.cnaeSecundario ? this.cliente.cnaeSecundario.split(';') : [];
                this.changePorte();
                if (this.cliente.tipoPessoa === 1) {
                    await this.getClienteAPI(this.cliente.cpfcnpj);
                } else if (this.cliente.tipoPessoa === 0) {
                    this.state.endereco = res.data.enderecos[0];
                    if (res && res.data && res.data.enderecos && res.data.enderecos.length > 0) {
                        this.state.endereco = res.data.enderecos[0];
                    } else {
                        this.state.endereco = null;
                    }
                }
                var contatos = [];
                for (let i = 0; i < this.cliente.contatoList.length; i++) {
                    var contatoBox = (
                        <div className={'box'} key={Math.random()}>
                            <label>
                                CONTATO
                                {this.isDN &&
                                    <Icon onClick={this.removeElemento} id={'contato'} data-index={this.cliente.contatoList[i].nome}>clear</Icon>}
                            </label>
                            <hr></hr>
                            {this.cliente.contatoList[i].nome}<br></br>{this.cliente.contatoList[i].setor}<br></br>{this.cliente.contatoList[i].telefone}<br></br>{this.cliente.contatoList[i].email}
                        </div>
                    );
                    contatos.push(contatoBox);
                }
                this.setState({ contatoBoxList: contatos }, this.closeLoading());
            }).catch(error => {
                this.closeLoading();
                // console.log(error);
            });
        }
    };

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getReceitaApiURL = () => {
        API.get('rest/sistema/Parametro?chave=URL_API_RECEITA').then(res => {
            this.receitaApiURL = res.data[0].valorstring;
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao realizar GET da URL da API de CNPJs.', 'error');
            console.log(error);
        });
    };

    componentDidMount() {
        if (this.props.idCliente == null) this.closeLoading();
    };

    clienteModel(isAtivo) {
        this.cnaeSecundarioList = [];
        return {
            isAtivo: isAtivo, tipoPessoa: '', tipoCnpj: null, documentoEstrangeiro: '', cpfcnpj: '', inscricaoEstadual: '', cnae: '', cnaeSecundario: '', nome: '', razaoSocial: '',
            porteCliente: null, contatoList: [], dataAtualizacao: null, dataCadastro: new Date(), isUnidadeSenai: false, pais: '', usuario: { id: this.props.usuarioConectado.id }
        };
    };

    enderecoModel() {
        return { logradouro: '', bairro: '', cep: '', municipio: {}, numero: '', cliente: {}, complemento: '', isPrincipal: false };
    };

    contatoModel() {
        return { nome: '', setor: '', telefone: '', email: '' };
    };

    changeInput = async (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'cpfcnpj') {
                this.cliente.cpfcnpj = e.target.value != null ? Masks.maskByTipoPessoa(e.target.value, this.cliente.tipoPessoa) : e.target.value;
                if (this.cliente.tipoPessoa === 1 && this.validateCpfCnpj()) {
                    try {
                        const existsCliente = await newBackend.get(`/cliente/${this.cliente.cpfcnpj.replace(/[^\d]+/g, "")}`);
                        if (existsCliente.data && existsCliente.data.cpfcnpj) {
                            this.showToast('CNPJ já cadastrado.', 'error');
                            this.cliente.nome = '';
                            this.cliente.razaoSocial = '';
                            this.cliente.cnae = '';
                            this.cliente.cnaeSecundario = '';
                            this.cnaeSecundarioList = [];
                            this.cliente.tipoPessoa = 1;
                            this.setState({ endereco: this.enderecoModel(), municipio: '', unidadeFederativa: '', validateEndereco: false });
                        } else {
                            this.getClienteAPI(this.cliente.cpfcnpj);
                        }
                    } catch (error) {
                        if (error.response && error.response.status === 404) {
                            this.getClienteAPI(this.cliente.cpfcnpj);
                        } else {
                            this.showToast('Erro ao buscar CNPJ.', 'error');
                        }
                    }
                }
            } else if (e.target.id === 'cnae') {
                this.cliente[e.target.id] = Masks.maskCnae(e.target.value);
            } else {
                this.cliente[e.target.id] = e.target.value != null ? e.target.value.toUpperCase() : e.target.value;
            }
            if (this.errors[e.target.id] != null && (e.target.value == null || e.target.value === '')) {
                this.errors[e.target.id] = 'Campo obrigatório';
            } else if (this.errors[e.target.id] != null) {
                this.errors[e.target.id] = '';
            }
        } else if (e != null && e.type != null) {
            if (e.type === 'tipoPessoa' && e.id !== this.cliente.tipoPessoa) {
                this.cliente = this.clienteModel(this.cliente.isAtivo);
                this.cliente.tipoPessoa = e.id;
                this.setState({ enderecoBoxList: [], contatoBoxList: [] });
            } else if (e.type === 'tipoPessoa' && e.id === this.cliente.tipoPessoa) {
                this.cliente.tipoPessoa = e.id;
            } else if (e.type === 'tipoCnpj') {
                this.cliente.tipoCnpj = { id: e.id };
            } else if (e.type === 'porteRais') {
                this.cliente.porteRais = e.id;
                this.cliente.numeroDeFuncionarios = e.value;
            } else if (e.type === 'porteFaturamento') {
                this.cliente.porteFaturamento = e.id;
                this.cliente.faturamento = e.value;
            }
            else {
                this.cliente[e.type] = e.id != null ? e : e.valor;
            }
        }
        this.setState(this.state);
    };

    getClienteAPI = async (cnpj) => {
        this.showLoading();
        cnpj = cnpj.replace(/[^\d]+/g, "");
        await newBackend.get(`/busca-cnpj/novo/${cnpj}`).then(async res => {
            let client = res.data.cliente;
            this.cliente.razaoSocial = client.razaoSocial;
            this.cliente.nome = client.nomeFantasia && client.nomeFantasia !== '' ? client.nomeFantasia : client.razaoSocial;
            this.cliente.cnae = client.cnaePrincipal;
            this.cliente.cnaeSecundario = client.cnaeSecundario.join(";");
            this.cliente.porte = client.porte;
            this.cliente.pais = 'BRA';
            this.changePorte();
            this.cnaeSecundarioList = client.cnaeSecundario;
            let enderecoPrincipal = this.enderecoModel();
            enderecoPrincipal.isPrincipal = true;
            enderecoPrincipal.cep = client.cep;
            enderecoPrincipal.complemento = client.complemento.toUpperCase();
            enderecoPrincipal.numero = client.numero;
            enderecoPrincipal.logradouro = client.logradouro;
            enderecoPrincipal.bairro = client.bairro;
            await this.getMunicipio(client.codigoIbge, enderecoPrincipal);
        }).catch(error => {
            this.closeLoading();
            let resposta = error.response;
            if (resposta && resposta.data && resposta.data.code === 500 && (resposta.data.response.includes('não foi encontrado') || resposta.data.response.includes('inválido'))) {
                this.errors['cpfcnpj'] = 'CNPJ inválido ou não encontrado na base.';
                this.showToast('CNPJ inválido ou não encontrado na base de CNPJs. Favor solicitar o cadastro via Suporte.', 'error');
            } else if (resposta && resposta.data && resposta.data.code === 500 && resposta.data.response.includes('ibge')) {
                this.showToast('Código IBGE do CNPJ não localizado na base. Favor solicitar o cadastro do cliente via Suporte.', 'error');
            } else if (resposta && resposta.data && resposta.data.businessRuleCode === 4127) {
                this.showToast('CNPJ inserido é inválido.', 'error');
            }
            else {
                this.showToast('Error ao realizar GET do cliente na API de CNPJs.', 'error');
            }
            this.cliente.nome = '';
            this.cliente.razaoSocial = '';
            this.cliente.cnae = '';
            this.cliente.cnaeSecundario = '';
            this.changePorte();
            this.cnaeSecundarioList = [];
            this.cliente.tipoPessoa = 1;
            this.setState({ endereco: this.enderecoModel(), municipio: '', unidadeFederativa: '', validateEndereco: false });
            console.log(error);
        });
    };

    changeEndereco = (e) => {
        // Garante que endereco seja um objeto, mesmo que this.state.endereco seja null
        var endereco = this.state.endereco || {};

        if (e != null && e.target != null) {
            if (e.target.id === 'cep') {
                endereco.cep = e.target.value.substring(0, 10);
                this.getCidade(e.target.value.substring(0, 10));
            } else {
                if (e.target.type === 'text') {
                    endereco[e.target.id] = e.target.value != null ? e.target.value.toUpperCase() : e.target.value;
                } else {
                    endereco[e.target.id] = e.target.value;
                }
                if (this.errors[e.target.id] != null && (e.target.value == null || e.target.value === '')) {
                    this.errors[e.target.id] = 'Campo obrigatório';
                } else if (this.errors[e.target.id] != null) {
                    this.errors[e.target.id] = '';
                }
            }
        } else if (e != null && e.type != null) {
            endereco[e.type] = e.id != null ? e : e.valor;
        }

        this.setState({ endereco: endereco }, () => {
            this.setState({ validateEndereco: this.validateCliente(4) });
        });
    };


    changeContato = (e) => {
        var contato = this.state.contato;
        if (e != null && e.target != null) {
            if (e.target.value != null) {
                if (e.target.id === 'email') {
                    contato.email = e.target.value;
                } else if (e.target.id === 'telefone') {
                    contato.telefone = e.target.value.substring(0, 15);
                } else {
                    contato[e.target.id] = e.target.value.toUpperCase();
                }
            }

            if (e.target.id === "nome" && (e.target.value == null || e.target.value === "" || !this.validateCaracterEspecial(e.target.value))) {
                this.errors["contato" + e.target.id] = "Nome inválido";
            } else if (e.target.id === "setor" && !this.validateCaracterEspecial(e.target.value)) {
                this.errors["contato" + e.target.id] = "Setor inválido";
            } else if (e.target.id === 'email' && e.target.value !== '' && !this.validateEmail(e.target.value)) {
                this.errors['contato' + e.target.id] = 'Email inválido';
            } else if (e.target.id === 'telefone' && e.target.value.replace(/\D/g, '') !== '' && !this.validateTelefone(e.target.value.substring(0, 15))) {
                this.errors['contato' + e.target.id] = 'Telefone inválido';
            } else if (this.errors['contato' + e.target.id] != null) {
                this.errors['contato' + e.target.id] = '';
            }
        } else if (e != null && e.type != null) {
            contato[e.type] = e.id != null ? e : e.valor;
        }
        this.setState({ contato: contato, validateContato: this.validateCliente(5) });
    };

    changePorte = () => {
        if (this.cliente.porte === 'DEMAIS') {
            this.cliente.porte = 'Demais';
        } else if (this.cliente.porte === 'EMPRESA DE PEQUENO PORTE') {
            this.cliente.porte = 'Pequeno';
        } else if (this.cliente.porte === 'MICRO EMPRESA') {
            this.cliente.porte = 'Micro';
        }
    };

    classificaPorte = (porte) => {
        switch (porte) {
            case 1:
                return 'Micro';
            case 2:
                return 'Pequeno';
            case 3:
                return 'Médio';
            case 4:
                return 'Grande';
            case 5:
                return 'Micro';
            case 6:
                return 'Demais';
            default:
                return '';
        }
    }

    adicionaElemento = (e) => {
        if (e.target.id === 'endereco') {
            this.cliente.endereco = {
                ...this.state.endereco,
                idmunicipio: this.state.municipio.id
            }
        } else if (e.target.id === 'contato') {
            this.cliente.contatoList.push(this.state.contato);
            var contatoBox = (
                <div className={'box'} key={Math.random()}>
                    <label>
                        CONTATO
                        <Icon onClick={this.removeElemento} id={'contato'} data-index={this.state.contato.nome}>clear</Icon>
                    </label>
                    <hr></hr>
                    {this.state.contato.nome}<br></br>{this.state.contato.setor}<br></br>{this.state.contato.telefone}<br></br>{this.state.contato.email}
                </div>
            );
            let list = this.state.contatoBoxList;
            list.push(contatoBox);
            this.setState({ contato: this.contatoModel(), contatoBoxList: list, validateContato: false });
        }
    };

    removeElemento = (e) => {
        var list = [];
        var index = -1;
        if (e.target.id === 'contato') {
            index = this.cliente.contatoList.findIndex(obj => obj.nome === e.target.dataset.index);
            list = this.state.contatoBoxList;
            list.splice(index, 1);
            this.cliente.contatoList.splice(index, 1);
            this.setState({ contatoBoxList: list });
        }
    };

    getMunicipio = async (codigoIbge, endereco) => {
        await newBackend.get(`/municipio/${codigoIbge}`).then(async res => {
            endereco.municipio = res.data;
            this.closeLoading();
            await this.setState({ endereco: endereco, municipio: res.data.descricao, unidadeFederativa: res.data.unidadefederativa.sigla }, () => this.setState({ validateEndereco: this.validateCliente(4) }));
        }).catch(error => {
            this.closeLoading();
            console.log(error);
        });
    };

    getCidade = async (cep) => {
        var endereco = Object.assign({}, this.state.endereco);
        cep = cep.replace(/-/g, '').replace(/\./g, '');
        if (cep.length === 8) {
            this.showLoading();
            await axios.get('https://viacep.com.br/ws/' + cep + '/json').then(async res => {
                if (res.data.erro) {
                    this.errors.cep = 'CEP Inválido';
                    this.errors.logradouro = '';
                    this.errors.bairro = '';
                    endereco.logradouro = '';
                    endereco.bairro = '';
                    endereco.complemento = '';
                    endereco.municipio = {};
                    this.closeLoading();
                    this.setState({ endereco: endereco, municipio: '', unidadeFederativa: '', validateEndereco: false });
                } else {
                    this.errors.cep = '';
                    this.errors.logradouro = res.data.logradouro === '' ? 'Campo obrigatório' : '';
                    this.errors.bairro = res.data.bairro === '' ? 'Campo obrigatório' : '';
                    endereco.logradouro = res.data.logradouro.toUpperCase();
                    endereco.bairro = res.data.bairro.toUpperCase();
                    endereco.complemento = res.data.complemento.toUpperCase();
                    await this.getMunicipio(res.data.ibge, endereco);
                }
            }).catch(error => {
                this.errors.cep = 'CEP Inválido.';
                endereco.logradouro = '';
                endereco.bairro = '';
                endereco.complemento = '';
                endereco.municipio = {};
                this.closeLoading();
                this.setState({ endereco: endereco, municipio: '', unidadeFederativa: '', validateEndereco: false });
            });
        } else {
            this.errors.cep = 'CEP Inválido.';
            endereco.cep = cep;
            endereco.logradouro = '';
            endereco.bairro = '';
            endereco.complemento = '';
            endereco.numero = '';
            endereco.municipio = {};
            this.setState({ endereco: endereco, municipio: '', unidadeFederativa: '', validateEndereco: false });
        }
    };

    validateCliente = (step) => {
        switch (step) {
            case 0:
                if (this.cliente.tipoPessoa === 0 || this.cliente.tipoPessoa === 2) {
                    this.cliente.tipoCnpj = { id: 3 }
                    return this.cliente.nome && this.validateCpfCnpj() && this.cliente.pais;
                } else if (this.cliente.tipoPessoa === 1) {
                    const isTipoCnpjNotNull = !(this.cliente.tipoCnpj === null || (this.cliente.tipoCnpj && this.cliente.tipoCnpj.id === null));
                    return this.cliente.nome && this.validateCpfCnpj() && this.cliente.razaoSocial && this.cliente.cnae && isTipoCnpjNotNull;
                } else {
                    return this.cliente.nome && this.validateCpfCnpj() && this.cliente.razaoSocial && this.cliente.tipoCnpj;
                }
            case 1:
                return !(this.cliente.tipoPessoa === 1 || (this.cliente.tipoPessoa === 0 && this.cliente.pais === 'BRA')) || (this.state.endereco && this.state.endereco.cep && this.state.endereco.logradouro && this.validateCep() && this.errors.logradouro === '' && this.errors.bairro === '' && this.state.endereco.municipio?.id);
            case 2:
                return this.cliente.contatoList && this.cliente.contatoList.length > 0;
            case 3:
                return (this.props.idCliente != null && !isNaN(this.props.idCliente))
                    || this.props.idCliente == null;
            case 4:
                return this.state.endereco && this.validateCep() && this.errors.logradouro === '' && this.errors.bairro === '' && this.state.endereco.municipio?.id;
            case 5:
                return this.state.contato && this.state.contato.nome && this.errors.contatoemail === '' && this.errors.contatotelefone === '' && this.errors.contatonome === ''
                && this.errors.contatosetor === '' && ((this.state.contato.email != null && this.state.contato.email !== '') || (this.state.contato.telefone != null && this.state.contato.telefone !== ''));
            default:
                return true;
        }
    };

    validateCpfCnpj() {
        var cpf = this.cliente.cpfcnpj.replace(/\./g, '').replace(/-/g, '').replace('/', '').replace(' ', '').replace(' ', '');

        if ((this.cliente.tipoPessoa === 0 && cpf.length >= 11 && !isNaN(cpf)) ||
            (this.cliente.tipoPessoa === 1 && cpf.length === 14 && !isNaN(cpf)) ||
            ((this.cliente.tipoPessoa === 2 || this.cliente.tipoPessoa === 3) && cpf.length > 0)) {
            this.errors.cpfcnpj = '';
            return true;
        } else {
            this.errors.cpfcnpj = 'Campo inválido.'
            return false;
        }
    };

    validateCep() {
        let cep = this.state.endereco.cep.replace(/-/g, '').replace(/\./g, '');
        return cep.length === 8 && !isNaN(cep);
    };

    retirarFormatacao(campoTexto) {
        campoTexto.value = campoTexto.value.replace(/(\.|\/|\-)/g, ""); //eslint-disable-line
    }

    validateCaracterEspecial(nome) {
        return new RegExp("^[A-Za-zÀ-ÖØ-öø-ÿ\\s]+$").test(nome);
      }
    
      validateEmail(email) {
        return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
      }
    
      validateTelefone(telefone) {
         //retira todos os caracteres menos os numeros
        telefone = telefone.replace(/\D/g, '');
    
        //verifica se tem a qtde de numero correto
        if (!(telefone.length >= 10 && telefone.length <= 11)) return false;
     
        //Se tiver 11 caracteres, verificar se começa com 9 o celular
        if (telefone.length === 11 && parseInt(telefone.substring(2, 3)) !== 9) return false;
    
        //verifica se não é nenhum numero digitado errado
        for (var n = 0; n < 10; n++) {
            if (telefone === new Array(11).join(n) || telefone === new Array(12).join(n)) return false;
        }
    
        //DDDs validos
        var codigosDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19,
            21, 22, 24, 27, 28, 31, 32, 33, 34,
            35, 37, 38, 41, 42, 43, 44, 45, 46,
            47, 48, 49, 51, 53, 54, 55, 61, 62,
            64, 63, 65, 66, 67, 68, 69, 71, 73,
            74, 75, 77, 79, 81, 82, 83, 84, 85,
            86, 87, 88, 89, 91, 92, 93, 94, 95,
            96, 97, 98, 99];
    
         // verifica se o DDD é valido
        if (codigosDDD.indexOf(parseInt(telefone.substring(0, 2))) === -1) return false; 
        return true;
      }

    saveCliente = () => {
        if (this.cliente.tipoPessoa === 0 || this.cliente.tipoPessoa === 1)
            this.cliente.cpfcnpj = this.cliente.cpfcnpj.replace(/\./g, '').replace(/-/g, '').replace('/', '').replace(' ', '');
        if (this.cliente.porteRais === 5) {
            this.cliente.porteRais = 1;
        }

        const clientToSend = {
            id: this.cliente.id ?? null,
            cnae: this.cliente.cnae ?? null,
            cpfcnpj: this.cliente.tipoPessoa === 0 || this.cliente.tipoPessoa === 1 ? this.cliente.cpfcnpj : null,
            inscricaoestadual: this.cliente.inscricaoEstadual ?? null,
            nome: this.cliente.nome,
            razaosocial: this.cliente.razaoSocial,
            cnaesecundario: this.cliente.cnaeSecundario ?? null,
            tipopessoa: this.cliente.tipoPessoa,
            idtipocnpj: this.cliente.tipoCnpj.id ?? null,
            endereco: {
                bairro: this.state.endereco.bairro,
                cep: this.state.endereco.cep,
                complemento: this.state.endereco.complemento,
                logradouro: this.state.endereco.logradouro,
                numero: this.state.endereco.numero,
                idmunicipio: this.state.endereco.municipio.id,
                isprincipal: true
            },
            contatoList: this.cliente.contatoList,
            documentoestrangeiro: this.cliente.tipoPessoa === 2 || this.cliente.tipoPessoa === 3 ? this.cliente.cpfcnpj : null,
            pais: this.cliente.pais,
            porte_cliente_rais: this.cliente.porteRais ?? null,
            porte_cliente_faturamento: this.cliente.porteFaturamento ?? null,
            isativo: this.cliente.isAtivo,
        }

        this.showLoading();
        if (this.cliente.id == null) {
            newBackend.post('cliente', clientToSend).then(res => {
                this.closeLoading();
                if (this.props.asModal) {
                    const novoCliente = {
                        ...res.data,
                        enderecoList: this.state.endereco ? [
                            {
                                bairro: this.state.endereco.bairro,
                                cep: this.state.endereco.cep,
                                complemento: this.state.endereco.complemento,
                                logradouro: this.state.endereco.logradouro,
                                numero: this.state.endereco.numero,
                                isprincipal: true,
                                municipio: {
                                    id: this.state.endereco.municipio.id,
                                    descricao: this.state.endereco.municipio.descricao,
                                }
                            }
                        ] : []
                    };
                    this.cliente = novoCliente;
                    this.showToast('Cliente cadastrado com sucesso!', 'success');
                    this.props.updateCliente(this.cliente);
                    this.props.closeModal(res.data);
                } else {
                    this.showToast('Cliente cadastrado com sucesso!', 'success', '/cad/pessoa/cliente');
                }
            }).catch(error => {
                this.closeLoading();
                if (error.response.data != null && error.response.data[0] != null && error.response.data[0].path.includes('cpfcnpj'))
                    this.showToast('Não foi possível cadastrar cliente pois CPF/CNPJ já está cadastrado.', 'error');
                else
                    this.showToast('Error ao cadastrar cliente. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            newBackend.post('cliente', clientToSend).then(res => {
                this.closeLoading();
                this.showToast('Cliente editado com sucesso!', 'success', '/cad/pessoa/cliente');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao editar cliente. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    };

    getTipoCliente(opcao) {
        switch (opcao) {
            case 0:
                return 'Pessoa Física';
            case 1:
                return 'Pessoa Jurídica';
            case 2:
                return 'Pessoa Física Estrangeira';
            case 3:
                return 'Pessoa Jurídica Estrangeira';
            default:
                return '';
        }
    };

    getCpfCnpj(opcao) {
        switch (opcao) {
            case 0:
                return 'CPF';
            case 1:
                return 'CNPJ';
            case 2:
                return 'Passaporte';
            case 3:
                return 'Documento';
            default:
                return '';
        }
    };

    getSteps = () => {
        return (
            [<Grid container className={'step'}>
                <Grid item xs={12} md={10} lg={9} xl={(this.props.asModal ? 9 : 5)}>
                    <Grid container>
                        <Grid item className={'paddingForm'} style={{ marginBottom: '20px' }}>
                            {this.alertaTipoCnpj && <AlertTipoCnpj />}
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <InputSelect label={'Status'} suggestions={this.ativoList} itemLabel={'label'} getInput={this.changeInput} id={'isAtivo'} itemKey={'label'}
                                value={this.cliente.isAtivo ? this.ativoList[0] : this.ativoList[1]} initialSelectedItem={this.cliente.isAtivo ? this.ativoList[0] : this.ativoList[1]}
                                error={this.errors.isAtivo} disabled={!this.isDN} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <InputSelect label={'Escolha o Tipo'} suggestions={this.tipoClienteList} itemKey={'id'} itemLabel={'label'} id={'id'}
                                getInput={this.changeInput} initialSelectedItem={{ id: this.cliente.tipoPessoa != null ? this.cliente.tipoPessoa : undefined }}
                                error={this.errors.tipoPessoa} disabled={this.cliente.id ? true : false} />
                        </Grid>
                        {this.cliente.tipoPessoa !== '' ?
                            <Grid container>
                                <Grid item xs={12} sm={6} className={'paddingForm'}>
                                    <FormField type={'text'} label={this.getCpfCnpj(this.cliente.tipoPessoa)} id={'cpfcnpj'} inputValue={Masks.maskByTipoPessoa(this.cliente.cpfcnpj, this.cliente.tipoPessoa)}
                                        changeValue={this.changeInput} error={this.errors.cpfcnpj} disabled={!this.isDN || this.pageTitle === 'Editar Cliente'} />
                                    {this.cliente.porte && this.cliente.tipoPessoa === 1 && (<span className={'span-porte'}>Porte: {this.cliente.porte}</span>)}
                                </Grid>
                                <Grid item xs={12} sm={6} className={'paddingForm'}>
                                    <FormField type={'text'} label={this.cliente.tipoPessoa === 0 || this.cliente.tipoPessoa === 2 ? 'Nome' : 'Nome Fantasia'} id={'nome'} inputValue={this.cliente.nome}
                                        changeValue={this.changeInput} error={this.errors.nome} maxLength={200} disabled={this.cliente.tipoPessoa === 1} />
                                </Grid>
                                <Grid item xs={12} sm={6} className={'paddingForm'} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>
                                    <FormField type={'text'} label={'Inscrição Estadual'} id={'inscricaoEstadual'} inputValue={this.cliente.inscricaoEstadual}
                                        changeValue={this.changeInput} maxLength={100} disabled={!this.isDN} />
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }} className={'paddingForm'}>
                                    <FormField type={'text'} label={'Razão Social'} id={'razaoSocial'} inputValue={this.cliente.razaoSocial} changeValue={this.changeInput}
                                        error={this.errors.razaoSocial} maxLength={'100'} disabled={this.cliente.tipoPessoa === 1} />
                                </Grid>
                                <Grid item xs={12} sm={6} className={'paddingForm'} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }}>
                                    <InputSelect label={'Escolha o Nº de Funcionários (Declaratório)'} suggestions={this.tipoPorteFuncionarios} itemKey={'id'} itemLabel={'label'} id={'id'}
                                        getInput={this.changeInput} initialSelectedItem={{ id: this.cliente.porteRais != null ? this.cliente.porteRais : undefined }}
                                        error={this.errors.porteRais} />
                                    {this.cliente.porteRais && (<span className={'span-porte'}>Porte Rais: {this.classificaPorte(this.cliente.porteRais)}</span>)}
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }} className={'paddingForm grid-porte'}>
                                    <InputSelect label={'Escolha o Faturamento (Declaratório)'} suggestions={this.tipoPorteFaturamento} itemKey={'id'} itemLabel={'label'} id={'id'}
                                        getInput={this.changeInput} initialSelectedItem={{ id: this.cliente.porteFaturamento != null ? this.cliente.porteFaturamento : 'undefined' }}
                                        error={this.errors.porteFaturamento} />
                                    {this.cliente.porteFaturamento && (<span className={'span-porte'}>Porte faturamento: {this.classificaPorte(this.cliente.porteFaturamento)}</span>)}
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }} className={'paddingForm grid-porte'}>
                                    <InputSelect label={'Classificação'} suggestions={this.tipoCnpjList} itemKey={'id'} itemLabel={'label'} id={'id'}
                                        getInput={this.changeInput} initialSelectedItem={this.cliente.tipoCnpj != null ? this.cliente.tipoCnpj : undefined}
                                        error={this.errors.tipoCnpj} />
                                </Grid>
                                <Grid item xs={12} sm={6} className={'paddingForm'} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>
                                    <FormField type={'text'} label={'CNAE Principal'} id={'cnae'} inputValue={this.cliente.cnae} changeValue={this.changeInput}
                                        error={this.errors.cnae} maxLength={'100'} disabled={true} />
                                </Grid>
                                <Grid item xs={12} sm={6} className={'paddingForm'}>
                                    <InputSelect label={'País'} suggestions={PaisList} itemKey={'valor'} itemLabel={'nome'} id={'pais'} getInput={this.changeInput}
                                        initialSelectedItem={{ valor: this.cliente.pais != null ? this.cliente.pais : undefined }} error={this.errors.pais} disabled={this.cliente.tipoPessoa === 1} />
                                </Grid>
                                <Grid item xs={12} sm={6} className={'paddingForm input-many-cliente'} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>
                                    <InputMany getInput={this.changeInput} label={'CNAEs Secundários'} chipLabel={''} id={'cnarSecundarioList'} initialSelectedItensList={this.cnaeSecundarioList}
                                        disabled={true} key={'input-many' + this.cnaeSecundarioList.toString()} />
                                </Grid>
                            </Grid>
                            :
                            <div></div>
                        }
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={10} lg={9} xl={(this.props.asModal ? 9 : 5)}>
                    {this.cliente.tipoPessoa === 2 || this.cliente.tipoPessoa === 3 || (this.cliente.tipoPessoa === 0 && this.cliente.pais !== 'BRA') ?
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={'tipo-estrangeiro'}>No momento o SGT ainda não suporta endereços estrangeiros.</div>
                            </Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={12} sm={4} className={'paddingForm'}>
                                <FormField type={'text'} label={'CEP'} id={'cep'}
                                    inputValue={Masks.maskCep((this.state.endereco || {}).cep || '')}
                                    changeValue={this.changeEndereco}
                                    error={this.errors.cep} maxLength={'45'} disabled={!this.isDN || this.cliente.tipoPessoa === 1} />
                            </Grid>
                            <Grid item xs={12} sm={8} className={'paddingForm'}>
                                <FormField type={'text'} label={'Logradouro'} id={'logradouro'}
                                    inputValue={(this.state.endereco || {}).logradouro || ''}
                                    changeValue={this.changeEndereco}
                                    error={this.errors.logradouro} maxLength={'45'} disabled={!this.isDN || this.cliente.tipoPessoa === 1} />
                            </Grid>
                            <Grid item xs={12} sm={2} className={'paddingForm'}>
                                <FormField type={'text'} label={'Número'} id={'numero'}
                                    inputValue={(this.state.endereco || {}).numero || ''}
                                    changeValue={this.changeEndereco}
                                    maxLength={'45'} disabled={!this.isDN || this.cliente.tipoPessoa === 1} />
                            </Grid>
                            <Grid item xs={12} sm={10} className={'paddingForm'}>
                                <FormField type={'text'} label={'Complemento'} id={'complemento'}
                                    inputValue={(this.state.endereco || {}).complemento || ''} changeValue={this.changeEndereco}
                                    maxLength={'45'} disabled={!this.isDN || this.cliente.tipoPessoa === 1} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={'paddingForm'}>
                                <FormField type={'text'} label={'Bairro'} id={'bairro'}
                                    inputValue={(this.state.endereco || {}).bairro || ''}
                                    changeValue={this.changeEndereco}
                                    error={this.errors.bairro} maxLength={'45'} disabled={!this.isDN || this.cliente.tipoPessoa === 1} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={'paddingForm'}>
                                <FormField type={'text'} label={'Cidade'} id={'municipio'}
                                    inputValue={this.state.municipio || ''}
                                    changeValue={this.changeEndereco} disabled={'disabled'}
                                    error={this.errors.municipio} maxLength={'45'} />
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={10} lg={9} xl={(this.props.asModal ? 9 : 5)}>
                    <Grid container>
                        <Grid item xs={12} sm={9} className={'paddingForm'}>
                            <FormField type={'text'} label={'Nome'} id={'nome'} inputValue={this.state.contato.nome} changeValue={this.changeContato}
                                error={this.errors.contatonome} maxLength={'100'} />
                        </Grid>
                        <Grid item xs={12} sm={3} className={'paddingForm'}>
                            <FormField type={'text'} label={'Setor'} id={'setor'} inputValue={this.state.contato.setor} changeValue={this.changeContato}
                                error={this.errors.contatosetor} maxLength={'45'} />
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <FormField type={'text'} label={'Telefone'} id={'telefone'} inputValue={Masks.maskTelefone(this.state.contato.telefone)} changeValue={this.changeContato}
                                error={this.errors.contatotelefone} maxLength={'45'} />
                        </Grid>
                        <Grid item xs={12} sm={8} className={'paddingForm'}>
                            <FormField type={'text'} label={'Email'} id={'email'} inputValue={this.state.contato.email} changeValue={this.changeContato}
                                error={this.errors.contatoemail} maxLength={'45'} />
                        </Grid>
                        <Grid item xs={12} className={'paddingForm'}>
                            <div style={{ 'float': 'right' }}>
                                <GenericButton color={'lightBlue'} subClass={'basic-button'} label={'Adicionar Contato'} click={this.adicionaElemento} id={'contato'}
                                    disabled={!this.state.validateContato} />
                            </div>
                        </Grid>
                        {this.state.contatoBoxList.length > 0 ?
                            <Grid item xs={12} className={'paddingForm'}>
                                <label className={'radio-label'}>Contatos associados</label>
                                <div className={'box-grid'}>{this.state.contatoBoxList}</div>
                            </Grid>
                            :
                            <div></div>
                        }
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} sm={10} md={6} lg={9} xl={(this.props.asModal ? 9 : 5)} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>1</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Informações Gerais</label>
                        <Grid container>
                            <Grid item xs={6}>Status</Grid>
                            <Grid item xs={6}>{this.cliente.isAtivo ? 'Ativo' : 'Inativo'}</Grid>
                            <Grid item xs={6}>Tipo de Cliente</Grid>
                            <Grid item xs={6}>{this.getTipoCliente(this.cliente.tipoPessoa)}</Grid>
                            <Grid item xs={6}>{this.getCpfCnpj(this.cliente.tipoPessoa)}</Grid>
                            {this.cliente.tipoPessoa === 1 ? (<Grid item xs={6}>{this.cliente.cpfcnpj} (Porte: {this.cliente.porte})</Grid>) : (<Grid item xs={6}>{this.cliente.cpfcnpj}</Grid>)}
                            <Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>Insc. Estadual</Grid>
                            <Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>{this.cliente.inscricaoEstadual}</Grid>
                            <Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }}>Razão Social</Grid>
                            <Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }}>{this.cliente.razaoSocial}</Grid>
                            <Grid item xs={6}>{this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? 'Nome Fantasia' : 'Nome'}</Grid>
                            <Grid item xs={6}>{this.cliente.nome}</Grid>
                            <Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>CNAE primário</Grid>
                            <Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>{this.cliente.cnae}</Grid>
                            <Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>CNAE Secundário</Grid>
                            <Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 ? '' : 'none' }}>{this.cliente.cnaeSecundario}</Grid>
                            {this.cliente.porteRais && (<Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }}>Porte RAIS</Grid>)}
                            {this.cliente.porteRais && (<Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }}>{this.classificaPorte(this.cliente.porteRais)}</Grid>)}
                            {this.cliente.porteFaturamento && (<Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }}>Porte Faturamento</Grid>)}
                            {this.cliente.porteFaturamento && (<Grid item xs={6} style={{ display: this.cliente.tipoPessoa === 1 || this.cliente.tipoPessoa === 3 ? '' : 'none' }}>{this.classificaPorte(this.cliente.porteFaturamento)}</Grid>)}
                            <Grid item xs={6}>País</Grid>
                            <Grid item xs={6}>{this.cliente.pais ? this.cliente.pais : 'BRA'}</Grid>
                        </Grid>
                    </div>
                </Grid>
                {(this.cliente.tipoPessoa === 1 || (this.cliente.tipoPessoa === 0 && this.cliente.pais === 'BRA')) && ((this.state.endereco || {}).cep) && (
                    <Grid item xs={12} sm={10} md={6} lg={9} xl={(this.props.asModal ? 9 : 5)} className={'review-div'}>
                        <div className={'review-square'}>
                            <label>2</label>
                        </div>
                        <div className={'review-info'}>
                            <label className={'review-title'}>Endereço</label>
                            <Grid item xs={6}>
                                {(this.state.endereco || {}).logradouro || ''} -
                                {(this.state.endereco || {}).numero || ''} -
                                {(this.state.endereco || {}).complemento || ''} <br />
                                {(this.state.endereco || {}).bairro || ''},
                                {((this.state.endereco || {}).municipio || {}).descricao || ''},
                                {Masks.maskCep(((this.state.endereco || {}).cep) || '')},
                                {this.state.unidadeFederativa || ''}
                            </Grid>
                        </div>
                    </Grid>
                )}
                <Grid item xs={12} sm={10} md={6} lg={9} xl={(this.props.asModal ? 9 : 5)} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>{this.cliente.tipoPessoa === 2 || this.cliente.tipoPessoa === 3 || (this.cliente.tipoPessoa === 0 && this.cliente.pais !== 'BRA') ? 2 : 3}</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Contatos</label>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={'box-grid-review'}>{this.state.contatoBoxList}</div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>]
        );
    };

    render() {

        if (this.props.asModal) {
            return (
                <Grid container className={'cliente'}>
                    <Stepper newTitle={this.titlesNew} titles={this.titles} steps={this.getSteps()} validation={this.validateCliente} sendForm={this.saveCliente} history={this.props.history} />
                </Grid>
            )
        } else {
            return (
                <Page icon={'client.svg'} label={this.pageTitle} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                    <Grid container className={'cliente'}>
                        <Stepper newTitle={this.titlesNew} titles={this.titles} steps={this.getSteps()} validation={this.validateCliente} sendForm={this.saveCliente} history={this.props.history} />
                    </Grid>
                </Page>
            )
        }
    };
}