import { TipoInputEnum } from "./tipoInput";

export const InputSmartFactoryEnum = {
    QuantidadeProduzidaInicial: { idProgramaToInput: 29, nome: "Quantidade produzida nas horas trabalhadas por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QuantidadeProduzidaFinal: { idProgramaToInput: 37, nome: "Quantidade produzida nas horas trabalhadas por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    QuantidadeHorasTurnoInicial: { idProgramaToInput: 30, nome: "Quantidade de horas por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QuantidadeHorasTurnoFinal: { idProgramaToInput: 38, nome: "Quantidade de horas por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    NumeroOperadoresTurnoInicial: { idProgramaToInput: 31, nome: "Número de operadores por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    NumeroOperadoresTurnoFinal: { idProgramaToInput: 39, nome: "Número de operadores por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    OEEInicial: { idProgramaToInput: 32, nome: "OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    OEEFinal: { idProgramaToInput: 40, nome: "OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    DisponibilidadeOEEInicial: { idProgramaToInput: 33, nome: "Disponibilidade OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    DisponibilidadeOEEFinal: { idProgramaToInput: 41, nome: "Disponibilidade OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    PerformanceOEEInicial: { idProgramaToInput: 32, nome: "Performance OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    PerformanceOEEFinal: { idProgramaToInput: 40, nome: "Performance OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    QualidadeOEEInicial: { idProgramaToInput: 35, nome: "Qualidade OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QualidadeOEEFinal: { idProgramaToInput: 43, nome: "Qualidade OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    ReducaoLeanInicial: { idProgramaToInput: 36, nome: "Redução de Movimentação Lean", id_tipo_input: TipoInputEnum.MedicaoInicial },
    ReducaoLeanFinal: { idProgramaToInput: 44, nome: "Redução de Movimentação Lean", id_tipo_input: TipoInputEnum.MedicaoFinal },
};