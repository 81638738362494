import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

Sentry.init({
  dsn: "https://76110820e5758f01e151ddefedbc8be4@sentry.isitics.com/4",
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] }), new Sentry.Replay({maskAllText: false, maskAllInputs: false, blockAllMedia: false})],
  
   beforeSend: (event) => {
    // Desabilita o Sentry no Localhost
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
  tracesSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0
});



ReactDOM.render(<App />, document.getElementById("root"));
