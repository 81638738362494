import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { Component } from 'react';
import { BASE_URL, newBackend } from '../../../comm/API';
import EmptyList from '../../../components/emptyList/emptyList';
import FormField from '../../../components/formField/formField';
import GenericButton from '../../../components/genericButton/genericButton';
import InputSelect from '../../../components/inputselect/inputselect';
import SimpleModal from '../../../components/modal/modal';
import ProgressBar from '../../../components/progressbar/progressbar';
import Table from '../../../components/table/table';
import { AtendimentoModel } from '../../atendimento/atendimentoNovoUtil';
import API from './../../../comm/API';
import Page from './../../../components/page/page';
import './apropriaremgrupo.scss';

export default class ApropriarEmGrupo extends Component {
    content = [];
    types = ['label', 'inputNumber', 'label', 'label', 'label'];
    producaoApropriadaList = [];
    atendimentoList = [];
    atendimentoBackNovo = {};
    errors = { dataInicio: '', dataFim: '' }
    modalProducao = false;
    modalHistorico = false;
    modalBusca = false;
    // canSave = false;
    canEdit = false;
    colaborador = {
        nome: "",
        cpf: "",
        numeroDeHorasDeTrabalho: 0,
        urlFoto: "dist/img/user-login.png",
        telefone: "",
        linkCurriculumVitae: "",
        isAtivo: true,
        isLaboratorio: false,
        isTransferidoParaIndustria: false,
        numeroCentroResponsabilidade: "",
        email: "",
        formacaoColaborador: null,
        tipoContratoColaborador: null,
        funcaoColaborador: null,
        unidade: null,
        unidadeFederativa: null,
        colaboradorSkillList: [],
        colaboradorProdutoRegionalList: []
    };

    titles = [
        { id: '0', canOrder: true, label: 'ID' },
        { id: '1', canOrder: true, label: 'Nº' },
        { id: '2', canOrder: true, label: 'Entidade atendida' },
        { id: '3', canOrder: true, label: 'Produto Regional' },
        { id: '5', canOrder: true, label: 'Título' },
        { id: '6', canOrder: true, label: 'Prod. no período' },
        { id: '7', canOrder: false, label: 'Prod. Realizada/Prevista' }];
    lines = [];
    anoList = [];

    atendimentoLines = [];

    atendimendoLineLote = [];

    atendimentoTitles = [
        { id: '0', canOrder: true, label: 'Colaborador' },
        { id: '1', canOrder: true, label: 'Produção' },
        { id: '3', canOrder: true, label: 'Apropriada no período' },
        { id: '2', canOrder: true, label: 'Apropriação total' },
        { id: '4', canOrder: true, label: '' }];

    atendimentoTitlesLote = [
        { id: '0', canOrder: true, label: 'Apropriação' },
        { id: '1', canOrder: true, label: 'Produção' },
        { id: '3', canOrder: true, label: 'Apropriada no período' },
        { id: '2', canOrder: true, label: 'Apropriação total' },
        { id: '4', canOrder: true, label: '' }
    ];

    constructor(props) {
        super(props);
        let date = new Date();

        let anoAtual = date.getFullYear().toString();

        let mesAtual = String(date.getMonth() + 1).padStart(2, '0');

        // Primeiro dia do mês
        let primeiroDiaDoMes = new Date(anoAtual, mesAtual, 1);
        primeiroDiaDoMes = this.formatData(primeiroDiaDoMes).split('-');
        primeiroDiaDoMes = primeiroDiaDoMes[2].padStart(2, '0')

        // Último dia do mês
        let ultimoDiaDoMes = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        ultimoDiaDoMes = ultimoDiaDoMes.toString()
        // Data inicial
        let dataDeInicio = `${anoAtual}-${mesAtual}-${primeiroDiaDoMes}`;

        // Data final
        let dataDeFinalizacao = `${anoAtual}-${mesAtual}-${ultimoDiaDoMes}`;

        let ano = new Date().getFullYear();
        for (var i = ano; i >= 2007; i--) {
            this.anoList.push({ id: i, type: 'ano', label: i });
        }
        this.state = {
            dataApropriacao: date, dataInicio: dataDeInicio, dataFim: dataDeFinalizacao, totalAtendimentos: 0, producaoList: [],
            ano: ano, emptyList: 'seeResults', busca: '', dataAtual: "", checkAtendimentoBMP: false,
        };

        this.initDataApropriacao();
    };

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.setState({ ...this.state, usuario: this.props.usuarioConectado, render: true });
        this.dateNow();
        this.closeLoading();
    }
    setAtendimento = (obj) => {
        this.atendimento = obj.atendimento;
        this.atendimentoLines = [];
        this.atendimendoLineLote = [];

        // Data inicial
        let datainicio = `${this.state.dataInicio}T00:00:00-03:00`;

        // Data final
        let datafim = `${this.state.dataFim}T23:59:59-03:00`;

        if (!this.state.dataInicio || !this.state.dataFim) {
            this.showToast('O período de produção não foi definido. Por favor, selecione a data.', 'error');
            return;
        }

        this.showLoading();
        let filtro = 'idAtendimento=' + this.atendimento.id + '&dataInicial=' + datainicio + '&dataFinal=' + datafim + '&idGrupoUsuario=NADA&ordenarPor=nome';
        API.get('/rest/gestao/ProducaoApropriadaGrupo/colaborador?' + filtro).then(res => {

            res.data.colaboradorList.forEach(colab => {
                this.atendimentoLines.push(this.createLine(colab, 'colaborador', this.atendimento));
            });
            res.data.parceiroList.forEach(parc => {
                this.atendimentoLines.push(this.createLine(parc, 'parceiro', this.atendimento));
            });
            res.data.regionalList.forEach(regional => {
                this.atendimentoLines.push(this.createLine(regional, 'regional', this.atendimento));
            });
            this.atendimentoLines.push(this.createLine(res.data.terceiro, 'terceiro', this.atendimento));

            this.atendimendoLineLote.push(this.createLine(res.data.lote, 'lote', this.atendimento));

            this.setState(this.state);
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao carregar lista. Por favor, entre em contato com o suporte.', 'error');
        });

        newBackend.get(`/atendimento/resumo/${this.atendimento.id}`).then(res => {
            this.atendimentoBackNovo = res.data;
            const codigoDNBMP = [412159, 412160, 42120, 412161, 42128];
            const codigoDNAtd = this.atendimentoBackNovo.produtoregional.produtonacional.codigodn;
            if (codigoDNBMP.includes(Number(codigoDNAtd))) {
                this.setState({ checkAtendimentoBMP: true });
            }   
        }).catch(error => {
            this.closeLoading();
            this.showToast('Ocorreu um erro desconhecido. Por favor, entre em contato com o suporte.', 'error');
        })
    }
    setAtendimentoFunc(obj) {
        this.atendimento = obj.atendimento;
        this.atendimentoLines = obj.lines;
        this.atendimendoLineLote = obj.lines;
        this.setState(this.state);
    }
    clearAtendimento = () => {
        this.atendimento = null;
        this.atendimentoLines = [];
        this.atendimendoLineLote = [];
        this.setState(this.state);
    }
    aplicarFiltro = () => {
        this.showLoading();
        var filtro = '';
        let colaborador = this.props.usuarioConectado.colaborador;
        this.content = [];
        this.producaoApropriadaList = [];
        this.modalBusca = false;

        // Data inicial
        let datainicio = this.state.dataInicio;

        // Data final
        let datafim = this.state.dataFim

        if (this.state.busca !== undefined && this.state.busca !== '') filtro = 'buscaLivreAtendimento=' + this.state.busca + '&';
        filtro += 'dataInicial=' + datainicio + '&';
        filtro += 'dataFinal=' + datafim + '&';
        if (colaborador != null && colaborador.isAtivo) filtro += 'unidade.id=' + colaborador.unidade.id + '&';
        else filtro += 'unidade.unidadePai.id=' + this.props.usuarioConectado.unidade.id + '&';
        filtro += 'atendimentoStatus.id=4&atendimentoStatus.id=8';

        API.get('/rest/gestao/Atendimento/findApropriarGrupo?' + filtro).then(res => {
            let atendimentos = res.data.map(atd => {
                let atend = new AtendimentoModel();
                atend.id = atd[0];
                atend.numero = atd[1];
                atend.cliente.nome = atd[2];
                atend.produtoRegional.nome = atd[3];
                atend.unidade.abreviacao = atd[4]
                atend.titulo = atd[5];
                atend.produtoLinha.id = atd[6];
                atend.numeroDeProducaoEstimada = atd[7];
                atend.numeroDeRelatorioEstimado = atd[8];

                atend.quantidadeApropriadas = atd[9];
                atend.quantidadeRelatorio = atd[10];
                atend.quantidadeApropriadasMes = atd[11] ? atd[11] : 0;
                atend.quantidadeRelatorioMes = atd[12] ? atd[12] : 0;
                const [year, month, day] = atd[13].split('-')
                atend.dataEmissao = new Date(year, month, day);
                return atend;
            });

            // Organiza os dados por ID
            function compare(a, b) {
                if (a.id < b.id)
                    return 1;
                if (a.id > b.id)
                    return - 1;
                return {};
            }

            let atendimentosOrdenados = atendimentos.sort(compare)

            this.setState({ ...this.state, atendimentos: atendimentosOrdenados, totalAtendimentos: res.data.length });
            if (res.data.length === 0) {
                this.lines = [];
                this.setState({ emptyList: 'noResults' });
                this.closeLoading();
            } else {
                this.setState({ emptyList: '' });
                this.getLines();
            }
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao carregar lista. Por favor, entre em contato com o suporte.', 'error');
        });

    }

    validateFiltro() {
        return !((this.errors.dataInicio === '') && (this.errors.dataFim === ''));
    }

    async getHistorico(url) {
        let list = (await API.get(url)).data;
        await this.setState({ producaoList: list });
        this.openHistorico();
    }

    getHistoricoColaborador = async (col) => {
        this.nomeColPar = col.nome;
        this.tipoColPar = 'Colaborador';
        this.getHistorico(`/rest/gestao/ProducaoApropriada/historico?atendimento.id=${this.atendimento.id}&colaborador.id=${col.id}`);
    }

    getHistoricoRegional = async (reg) => {
        this.nomeColPar = reg.abreviacao;
        this.tipoColPar = 'Regional';
        this.getHistorico(`/rest/gestao/ProducaoApropriada/historico?atendimento.id=${this.atendimento.id}&regional.id=${reg.id}`);
    }

    getHistoricoParceiro = async (par) => {
        this.nomeColPar = par.unidadeParceira;
        this.tipoColPar = 'Parceiro';
        this.getHistorico(`/rest/gestao/ProducaoApropriada/historico?atendimento.id=${this.atendimento.id}&unidade.id=${par.idUnidadeParceira}`);
    }

    getHistoricoTerceiro = async (ter) => {
        this.nomeColPar = 'Terceiro';
        this.tipoColPar = 'Parceiro';
        this.getHistorico(`/rest/gestao/ProducaoApropriada/historico?atendimento.id=${this.atendimento.id}&colaborador=is(null)&regional=is(null)&unidade=is(null)&isLote=false&isTerceiros=true`);
    }

    getHistoricoLote = async (lot) => {
        this.nomeColPar = 'Apropriação em lote';
        this.tipoColPar = 'Parceiro';
        this.getHistorico(`/rest/gestao/ProducaoApropriada/historico?atendimento.id=${this.atendimento.id}&colaborador=is(null)&regional=is(null)&unidade=is(null)&isLote=true`);
    }

    formatDataHistorico(d) {
        let date = new Date(d).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" });
        let dataList = date.split(' ')[0].split('/');
        return dataList[0] + '/' + dataList[1] + '/' + dataList[2]
    }
    getQuantidadeHorasApropriadas(item, parceiro) {
        let dataApropriacao = new Date(item.dataDeApropriacao);
        let date = new Date();
        let dataInicio = new Date(date.getFullYear(), date.getMonth(), 1);
        let dataFim = new Date(date.getFullYear(), date.getMonth(), this.daysInMonth(date.getMonth() + 1, date.getFullYear()));
        if (this.atendimento.produtoLinha.id === 3 || this.atendimento.produtoLinha.id === '3') {
            if (dataApropriacao.getTime() >= dataInicio.getTime() && dataApropriacao.getTime() <= dataFim.getTime()) {
                return (
                    <Grid>
                        <FormField style={{ margin: '0' }} inputValue={item.quantidadeApropriadas} label={"Ensaios"} type={'number'} changeValue={this.updateHorasProducao} id={item.id} min={0} max={99999999} disabled={parceiro.tipo === 'Parceiro' && parceiro.nome !== 'Terceiro'} />
                        <FormField style={{ margin: '0' }} inputValue={item.quantidadeRelatorio} label={"Relatórios/Laudos"} type={'number'} changeValue={this.updateRelatoriosProducao} id={`${item.id}-relatorio`} min={0} max={99999999} disabled={parceiro.tipo === 'Parceiro' && parceiro.nome !== 'Terceiro'} />
                    </Grid>
                );
            } else {
                return (
                    <Grid style={{ whiteSpace: "nowrap" }}>
                        <span>{item.quantidadeApropriadas} ensaios</span><br></br>
                        <span>{item.quantidadeRelatorio} relatórios/laudos</span>
                    </Grid>)
            }
        } else {
            if (dataApropriacao.getTime() >= dataInicio.getTime() && dataApropriacao.getTime() <= dataFim.getTime()) {
                return (<FormField inputValue={item.quantidadeApropriadas} type={'number'} changeValue={this.updateHorasProducao} id={item.id} min={0} max={99999999} disabled={parceiro.tipo === 'Parceiro' && parceiro.nome !== 'Terceiro' && parceiro.nome !== 'Apropriação em lote'} />);
            } else {
                return `${item.quantidadeApropriadas} h`;
            }
        }
    }

    updateHorasProducao = async (e) => {
        this.state.producaoList.forEach(item => {
            if (item.id === Number(e.target.id)) {
                item.quantidadeApropriadas = Number(e.target.value);
                item.alterado = true;
                item.dataDeApropriacao = new Date(item.dataDeApropriacao)
            };
        });
        await this.setState({ producaoList: this.state.producaoList });
    }
    updateRelatoriosProducao = async (e) => {
        this.state.producaoList.forEach(item => {
            if (item.id === Number(e.target.id.replace('-relatorio', ''))) {
                item.quantidadeRelatorio = Number(e.target.value);
                item.alterado = true;
                item.dataDeApropriacao = new Date(item.dataDeApropriacao)
            };
        });
        await this.setState({ producaoList: this.state.producaoList });
    }

    renderListProducao(parceiro) {
        return (
            this.state.producaoList
                .filter((item) => {
                    return (new Date(item.dataDeApropriacao)).getFullYear() === this.state.ano;
                })
                .sort((a, b) => a.dataDeApropriacao < b.dataDeApropriacao ? 1 : -1)
                .map(item => {
                    return (
                        <Grid container className={'colaborador'} key={item.id}>
                            <Grid item xs={6}>{item.usuario.nome}</Grid>
                            <Grid item xs={3}>{this.formatDataHistorico(item.dataDeApropriacao)}</Grid>
                            <Grid item xs={3}>{this.getQuantidadeHorasApropriadas(item, parceiro)}</Grid>
                        </Grid>
                    )
                })
        )
    }

    getInputTable(id, name, atendimento) {
        let inputTable
        if (atendimento.produtoLinha.id === 3 || atendimento.produtoLinha.id === '3') {
            inputTable = (
                <Grid style={{ width: '30%' }}>
                    <FormField
                        key={`ensaio-${name}-${id}`}
                        type={"number"}
                        label={"Ensaios"}
                        changeValue={this.changeProducaoApropriada}
                        id={id}
                        name={name}
                        data={"ensaio"}
                        subClassLabel={"label-table"}
                        min={0}
                        max={99999999}
                        disabled={this.disabledInput() || name === 'parceiro'} />

                    <FormField
                        key={`relatorio-${name}-${id}`}
                        type={"number"}
                        label={"Relatórios/Laudos"}
                        changeValue={this.changeProducaoApropriada}
                        id={id}
                        name={name}
                        data={"laudo"}
                        subClassLabel={"label-table"}
                        min={0}
                        max={99999999}
                        disabled={this.disabledInput() || name === 'parceiro'} />
                </Grid>
            );
        } else {
            inputTable = (
                <Grid style={{ width: '30%' }}>
                    <FormField
                        key={`${name}-${id}`}
                        type={"number"}
                        name={name}
                        changeValue={this.changeProducaoApropriada}
                        id={id}
                        data={"horas"}
                        min={0}
                        max={99999999}
                        disabled={this.disabledInput() || name === 'parceiro'} />
                </Grid>
            );
        }
        return inputTable
    }

    createLine(obj, type, atendimento) {
        if (type === 'colaborador') {
            return [
                <p style={{ width: 350 }}>
                    {obj.nome}
                </p>,

                this.getInputTable(obj.id, 'colaborador', atendimento),

                <label style={{ marginLeft: 40, fontSize: '13px' }}>{obj.numeroDeHorasDeTrabalho + 'h'}</label>,

                obj.totalProducao != null ? ((atendimento.produtoLinha.id === 3 || atendimento.produtoLinha.id === '3') ?

                    <label style={{ marginLeft: 60, fontSize: '13px' }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + ' ensaios'}

                        <br />

                        {obj.totalProducaoM.toLocaleString('pt-BR') + ' relatórios/laudos'}
                    </label>
                    :
                    <label style={{ marginLeft: 60 }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + 'h'}
                    </label>
                ) :

                    <label style={{ marginLeft: 50 }}>
                        0h
                    </label>,

                <GenericButton color={'darkBlue-outline'} id={obj.id} label={'Histórico'} subClass={'basic-button'} click={() => this.getHistoricoColaborador(obj)} />
            ]
        } else if (type === 'parceiro') {
            return [
                <p style={{ width: 350 }}>{obj.unidadeParceira}</p>,

                this.getInputTable(obj.idUnidadeParceira, 'parceiro', atendimento),
                '',

                obj.totalProducao != null ? ((atendimento.produtoLinha.id === 3 || atendimento.produtoLinha.id === '3') ?

                    <label style={{ fontSize: '13px' }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + ' ensaios'}

                        <br />

                        {obj.totalProducaoM.toLocaleString('pt-BR') + ' relatórios/laudos'}
                    </label>
                    :
                    <label style={{ marginLeft: 60 }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + 'h'}
                    </label>
                ) :

                    <label style={{ marginLeft: 50 }}>
                        0h
                    </label>,

                <GenericButton color={'darkBlue-outline'} id={obj.id} label={'Histórico'} subClass={'basic-button'} click={() => this.getHistoricoParceiro(obj)} />
            ]
        } else if (type === 'regional') {
            return [

                <p style={{ width: 350 }}>{obj.abreviacao}</p>,

                this.getInputTable(obj.id, 'regional', atendimento),
                '',

                obj.totalProducao != null ? ((atendimento.produtoLinha.id === 3 || atendimento.produtoLinha.id === '3') ?

                    <label style={{ fontSize: '13px' }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + ' ensaios'}

                        <br />

                        {obj.totalProducaoM.toLocaleString('pt-BR') + ' relatórios/laudos'}
                    </label>
                    :
                    <label style={{ marginLeft: 60 }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + 'h'}
                    </label>
                ) :

                    <label style={{ marginLeft: 50 }}>
                        0h
                    </label>,

                <GenericButton color={'darkBlue-outline'} id={obj.id} label={'Histórico'} subClass={'basic-button'} click={() => this.getHistoricoRegional(obj)} />
            ]
        } else if (type === 'lote') {
            return [

                <p style={{ width: 350 }}>APROPRIAÇÃO EM LOTE</p>,

                this.getInputTable(null, 'lote', atendimento),
                '',

                obj.totalProducao != null ? ((atendimento.produtoLinha.id === 3 || atendimento.produtoLinha.id === '3') ?

                    <label style={{ marginLeft: 40, fontSize: '13px' }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + ' ensaios'}

                        <br />

                        {obj.totalProducaoM.toLocaleString('pt-BR') + ' relatórios/laudos'}

                    </label>

                    :

                    <label style={{ marginLeft: 60 }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + 'h'}
                    </label>
                ) :

                    <label style={{ marginLeft: 50 }}>
                        0h
                    </label>,


                <GenericButton color={'darkBlue-outline'} id={obj.id} label={'Histórico'} subClass={'basic-button'} click={() => this.getHistoricoLote(obj)} />
            ]
        } else {
            return [
                <p style={{ width: 350 }}>
                    TERCEIRO
                </p>,
                this.getInputTable('', 'terceiro', atendimento),
                '',

                obj.totalProducao != null ? ((atendimento.produtoLinha.id === 3 || atendimento.produtoLinha.id === '3') ?


                    <label style={{ marginLeft: 40, fontSize: '13px' }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + ' ensaios'}

                        <br />

                        {obj.totalProducaoM.toLocaleString('pt-BR') + ' relatórios/laudos'}

                    </label>

                    :

                    <label style={{ marginLeft: 60 }}>
                        {obj.totalProducao.toLocaleString('pt-BR') + 'h'}
                    </label>
                ) :

                    <label style={{ marginLeft: 50 }}>
                        0h
                    </label>,

                <GenericButton color={'darkBlue-outline'} id={obj.id} label={'Histórico'} subClass={'basic-button'} click={() => this.getHistoricoTerceiro(obj)} />
            ]
        }

    }
    changeProducaoApropriada = (e) => {
        let self = this;
        let dataName = e.target.getAttribute('data')
        var producao = self.producaoApropriadaList.find(function (producao) { //atualizar valor da producao
            let encontrou = false;

            if (e.target.name == 'lote') {
                producao.isLote = true;
                producao.isTerceiros = false;
            } else if (e.target.name == 'terceiro') {
                producao.isLote = false;
                producao.isTerceiros = true
            } else {
                producao.isLote = false;
                producao.isTerceiros = false;
            }

            if ((producao.atendimento.id === self.atendimento.id) &&
                ((producao.colaborador && producao.colaborador.id === e.target.id) ||
                    (producao.unidade && producao.unidade.id === e.target.id) ||
                    (producao.regional && producao.regional.id === e.target.id) ||
                    (e.target.name === 'terceiro' && producao.colaborador === undefined && producao.unidade === undefined && producao.regional === undefined && producao.isTerceiros == true && producao.isLote == false) ||
                    (e.target.name === 'lote' && producao.colaborador === undefined && producao.unidade === undefined && producao.regional === undefined && producao.isLote == true && producao.isTerceiros == false)
                )) {

                if (dataName === 'horas' || dataName === 'ensaio') {
                    producao.quantidadeApropriadas = Number(e.target.value);
                } else if (dataName === 'laudo') {
                    producao.quantidadeRelatorio = Number(e.target.value);
                }
                encontrou = true;
            }
            return encontrou ? producao : null;
        });
        if (producao == null && Number(e.target.value) != null) { //cria producao se nao existir
            var atd = self.state.atendimentos.find(function (atd) {
                return atd.id === self.atendimento.id ? atd : null
            }
            );
            if (dataName === 'horas' || dataName === 'ensaio') {
                producao = self.producaoApropriadaModel(atd, self.state.usuario, Number(e.target.value), 0);
            } else {
                producao = self.producaoApropriadaModel(atd, self.state.usuario, 0, Number(e.target.value));
            }
            if (e.target.name === 'colaborador') {
                producao.colaborador = { id: e.target.id }
            } else if (e.target.name === 'parceiro') {
                producao.unidade = { id: e.target.id }
            } else if (e.target.name === 'regional') {
                producao.regional = { id: e.target.id }
            }
            self.producaoApropriadaList.push(producao);
        } else if (producao != null && Number(e.target.value) <= 0) { //remove producao se valor <= 0
            if (!producao.quantidadeApropriadas && !producao.quantidadeRelatorio) {
                var index = self.producaoApropriadaList.indexOf(producao)
                self.producaoApropriadaList.splice(index, 1);
            }
        }
        // self.canSave = self.producaoApropriadaList.length > 0 && this.validadeInput(self.producaoApropriadaList);
        this.setState(this.state);
    };

    canSave = () => {
        return this.producaoApropriadaList.length > 0 && this.validadeInput(this.producaoApropriadaList)
    }

    validadeInput = (lista) => {
        let retorno = true;
        for (let i = 0; i < lista.length; i++) {
            if (lista[i].quantidadeApropriadas > 99999999 || lista[i].quantidadeRelatorio > 99999999 ||
                lista[i].quantidadeRelatorio % 1 !== 0 || lista[i].quantidadeApropriadas % 1 !== 0 ||
                lista[i].quantidadeApropriadas < 0 || lista[i].quantidadeRelatorio < 0) {
                retorno = false;
                break;
            }
        }
        return retorno;
    };

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    };

    formatData(date) {
        if (typeof (date) !== 'string') {
            let dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                var dataList = date.split('T');;
                return dataList[0];
            } else {
                return date;
            }
        }
    }
    getLines = async () => {
        this.lines = []

        await this.state.atendimentos.forEach(async atd => {
            atd.totalProducaoAtAll = (await API.get(`/rest/gestao/ProducaoApropriada/sumProducaoAtendimentoRede/${atd.id}`)).data.somaProducao;
            atd.totalProducao = 0;
            atd.totalProducaoM = 0;
            let atdLines = [];

            let line = [];

            line.push(
                <p style={{ width: '15%', fontSize: 12, marginRight: 20 }}>
                    {atd.id}
                </p>
            );

            line.push(
                <p style={{ width: '15%', fontSize: 12, marginRight: 20 }}>
                    {atd.numero}
                </p>
            );

            line.push(
                <p style={{ width: 120, fontSize: 10, marginRight: 10 }}>
                    {atd.cliente.nome}
                </p>
            );

            line.push(
                <p style={{ width: 300, fontSize: 10, marginRight: 10 }}>
                    {atd.produtoRegional.nome}
                </p>
            );

            line.push(
                <p style={{ fontSize: 12, marginRight: 40 }}>
                    {atd.unidade.abreviacao}
                </p>
            );

            line.push(
                <p style={{ width: 340, fontSize: 12 }}>
                    {atd.titulo}
                </p>
            );

            if (atd.produtoLinha.id === 3 || atd.produtoLinha.id === '3') {
                line.push(
                    <label>
                        {atd.quantidadeApropriadasMes.toLocaleString('pt-BR')} ensaios
                        <br />
                        {atd.quantidadeRelatorioMes.toLocaleString('pt-BR')} relatórios/laudos
                    </label>);
            } else {
                line.push(
                    <p style={{ width: 90, fontSize: 12, fontWeight: 'bold', marginRight: 40, textAlign: 'center' }}>
                        {atd.quantidadeApropriadasMes.toLocaleString('pt-BR')}h
                    </p>

                );
            }
            line.push(
                <Grid className={'progress-line'}>
                    <ProgressBar idAtendimento={atd.id} producao={atd.numeroDeProducaoEstimada + atd.numeroDeRelatorioEstimado} total={atd.totalProducaoAtAll} key={atd.id} />
                </Grid>
            );

            line.push({ atendimento: atd, lines: atdLines });

            this.lines.push(line);
            this.setState(this.state);
            this.closeLoading();
        });

    }

    producaoApropriadaModel(atendimento, usuario, valorApropriado, valorRelatorio) {
        return {
            dataDeApropriacao: this.formatData(this.state.dataApropriacao),
            quantidadeApropriadas: valorApropriado,
            quantidadeRelatorio: valorRelatorio,
            atendimento: atendimento,
            usuario: usuario
        };
    };

    ProducaoException(message) {
        this.message = message;
        this.name = "ProducaoException";
    }

    disabledInput = () => {
        switch (this.atendimento.solicitacaoStatus) {
            case 'analise':
                return true;
            case 'analiseIA':
                return true;
            case 'analiseIPEA':
                return true;
            case 'analiseIE':
                return true;
            default:
                return false;
        }
    }

    validarProducao = () => {
        try {
            if (
                this.producaoApropriadaList !== [] &&
                this.producaoApropriadaList !== null
            ) {
                const dataLimite = new Date(2018, 6, 31, 23, 59, 59);
                const dataEmissao = this.atendimento.dataEmissao;
                this.setState({
                    producaoApropriadaList: this.producaoApropriadaList.forEach(
                        producao => {
                            producao.dataDeApropriacao = new Date();
                            if (
                                producao.regional &&
                                dataEmissao &&
                                dataEmissao.getTime() > dataLimite.getTime()
                            ) {
                                let error = { name: "ProducaoException", message: "Só é possível apropriar produção para regionais em atendimentos cadastrados antes de agosto de 2018!" }
                                throw error;
                            }
                        }
                    )
                });
                return true;
            } else {
                return false;
            }
        } catch (error) {
            this.showToast(error.name, error.message);
            return false;
        }
    }

    salvarProducao = () => {
        this.showLoading();
        var index = 0;
        if (this.producaoApropriadaList !== [] && this.producaoApropriadaList !== null) {

            this.producaoApropriadaList.forEach(producao => {

                producao.dataDeApropriacao = this.formatData(this.state.dataAtual) + 'T00:00:00';

                API.post('rest/gestao/ProducaoApropriada', producao).then(res => {
                    this.changeModal('modalProducao', false);
                    this.closeLoading();
                    if (index === this.producaoApropriadaList.length) {
                        this.producaoApropriadaList = [];
                        this.changeModal('modalProducao', false);
                        this.showToast('Produções salvas com sucesso!', 'success');
                        this.dateNow();
                        this.clearAtendimento();
                        this.aplicarFiltro();
                    }
                }).catch(error => {
                    this.changeModal('modalProducao', false);
                    this.closeLoading();
                    this.showToast('Error ao salvar produção apropriada.\nFavor, entrar em contato com o suporte.', 'error');
                });
                index = index + 1;
            });
        } else {
            this.closeLoading();
            this.showToast("Lista de apropriações vazia.", 'error');
        }
    }

    atualizarProducao = async () => {

        if (this.state.producaoList.length > 0) {
            this.showLoading();
            var quantSucesso = 0;
            var quantErrro = '';
            this.state.producaoList.forEach(async (producao, index) => {
                if (producao.alterado) {
                    try {
                        await API.post(`rest/gestao/ProducaoApropriada/${producao.id}`, producao);
                        quantSucesso = quantSucesso + 1;
                    } catch (error) {
                        quantErrro = producao.id + ',';
                    }
                };
                if (index === this.state.producaoList.length - 1) {
                    this.changeModal('modalHistorico', false);
                    //this.closeLoading();
                    if (quantErrro.length > 0) {
                        this.showToast('Ocorreu erro ao tentar atualizar as produções: ' + quantErrro, 'error');
                    } else {
                        this.showToast('Produções atualizadas com sucesso!', 'success');
                    }
                    this.clearAtendimento();
                    this.aplicarFiltro();
                }
            });
        }
    }

    changeBusca = (e) => {
        this.setState({ busca: e.target.value });
    }
    changeDataInicio = (e) => {
        this.setState({ dataInicio: e.target.value });
    }
    changeDataFim = (e) => {
        this.setState({ dataFim: e.target.value });
    }

    changeData = async (e) => {
        let datas = this.state;
        datas[e.target.id] = e.target.value

        /* Validação */
        if (e.target.value === '') {
            this.errors[e.target.id] = 'Campo obrigatório';
        } else if (this.formatData(datas.dataInicio) > this.formatData(datas.dataFim)) {
            this.errors['dataInicio'] = 'Data de início deve ser menor ou igual a data de fim';
            this.errors['dataFim'] = 'Data de início deve ser menor ou igual a data de fim';
        } else if (this.formatData(datas.dataInicio) <= this.formatData(datas.dataFim)) {
            this.errors['dataInicio'] = '';
            this.errors['dataFim'] = '';
        } else {
            this.errors[e.target.id] = '';
        }

        this.setState({ dataInicio: datas.dataInicio, dataFim: datas.dataFim })
    }

    initDataApropriacao = () => {
        var data = new Date();
        var ano = data.getFullYear();
        var mes = data.getMonth();
        let dia = String(data.getDate()).padStart(2, '0');

        if (dia >= 1 && dia <= 7) {
            if (mes === 0) {
                data = new Date(ano - 1, 11, 1).toLocaleDateString().split('/');
                this.firstDataApropriacaoProducao = data[2] + '-' + data[1] + '-' + data[0];
            } else {
                data = new Date(ano, mes - 1, 1).toLocaleDateString().split('/');
                this.firstDataApropriacaoProducao = data[2] + '-' + data[1] + '-' + data[0];
            }
        } else {
            data = new Date(ano, mes, 1).toLocaleDateString().split('/');
            this.firstDataApropriacaoProducao = data[2] + '-' + data[1] + '-' + data[0];
        }
        data = new Date(ano, mes, dia).toLocaleDateString().split('/');
        this.lastDataApropriacaoProducao = data[2] + '-' + data[1] + '-' + dia;
        this.setState(this.state);
    }

    dateNow = () => {
        let dataDeHoje = "";

        /* Pega a data atual */
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear().toString();

        dataDeHoje = [ano, mes, dia]

        dataDeHoje = `${dataDeHoje[0]}-${dataDeHoje[1]}-${dataDeHoje[2]}`;


        this.setState({ dataAtual: dataDeHoje });
    }

    changeDataApropriar = async (e) => {
        /* Data passada no modal */
        let novaData = e.target.value.split('-');
        let diaModal = parseInt(novaData[2])
        let mesModal = parseInt(novaData[1])
        let anoModal = parseInt(novaData[0])

        /* Pega a data atual */
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear().toString();

        /* Converte para inteiro o dia, mes e ano pego automaticamente */
        let setAno = parseInt(ano)
        let setMes = parseInt(mes)
        let setDia = parseInt(dia)

        /* Validação */
        if (diaModal <= setDia && mesModal <= setMes && anoModal <= setAno) {
            await this.setState({ dataAtual: novaData[0] + '-' + novaData[1] + '-' + novaData[2], dataInvalida: '' });
        }
        else {
            this.setState({ dataInvalida: 'Não é possível apropriar devido à data de aceitação de alguns atendimentos.', dataAtual: '' });
        }
    }

    changeModal(modal, status) {
        this[modal] = status;
        this.setState(this.state);
    }
    openHistorico() {
        this.modalHistorico = true;
        this.setState(this.state);
    }
    changeInput = (e) => {
        this.setState({ ano: e.label });
    }

    gerarRelatorio = () => {
        this.showLoading();
        let colaborador = this.props.usuarioConectado.colaborador;

        let dataInicio = `${this.state.dataInicio}`;

        let dataFim = `${this.state.dataFim}`;

        window.open(BASE_URL + `rest/gestao/ProducaoApropriada/${dataInicio}/${dataFim}/${colaborador.unidade.id}/relatorioProducaoApropiada.xls`);
        this.closeLoading();
    }


    gerarRelatorioHistorico = () => {
        this.showLoading();
        let colaborador = this.props.usuarioConectado.colaborador;
        let idAtendimento = this.atendimento.id;
        let dataInicio = this.state.dataInicio;

        let dataFim = this.state.dataFim;
        window.open(BASE_URL + `rest/gestao/ProducaoApropriada/${dataInicio}/${dataFim}/${colaborador.unidade.id}/${idAtendimento}/relatorioHistoricoProducaoApropiada.xls`);
        this.closeLoading();
    }

    render() {
        return (
            <Page icon={'clock.svg'} label={'Produção em Grupo'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'apropriacao-grupo'}>
                    {this.atendimento != null ?
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid className={'pagination-gerenciar-atendimento'}>
                                        <span onClick={() => this.setState({ modalProducao: false })}>
                                            <div className={'return-gerenciar-atendimento'} onClick={this.clearAtendimento}>
                                                <ArrowBackIosIcon style={{ color: '#077CDC', fontSize: '28px' }} />
                                                <p>Apropriação em grupo</p>
                                            </div>
                                        </span>
                                        <ArrowForwardIosIcon style={{ color: 'darkGrey', fontSize: '14px', marginRight: '10px' }} />
                                        <p style={{ color: '#077CDC' }}>{this.atendimento.titulo}</p>
                                    </Grid>

                                    <Grid item xs={12} lg={5} xl={7} className={'info-atendimento'}>
                                        <h1>{this.atendimento.titulo}</h1>

                                        <Grid style={{ display: 'column', width: '100%' }}>
                                            <Grid className={'Box-label'}>
                                                <Grid item xs={3}><h5>ID</h5></Grid>
                                                <Grid item xs={9}>{this.atendimento.id}</Grid>
                                            </Grid>
                                            <Grid className={'Box-label'}>
                                                <Grid item xs={3}><h5>Número</h5></Grid>
                                                <Grid item xs={9}>{this.atendimento.numero}</Grid>
                                            </Grid>

                                            <Grid className={'Box-label'}>
                                                <Grid item xs={3}><h5>Entidade atendida</h5></Grid>
                                                <Grid item xs={9}>{this.atendimento.cliente.nome}</Grid>
                                            </Grid>

                                            <Grid className={'Box-label'}>
                                                <Grid item xs={3}><h5>Unidade</h5></Grid>
                                                <Grid item xs={9}>{this.atendimento.unidade.abreviacao}</Grid>
                                            </Grid>

                                            <Grid className={'Box-label'}>
                                                <Grid item xs={3}><h5>Produto Regional</h5></Grid>
                                                <Grid item xs={9}>{this.atendimento.produtoRegional.nome}</Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3} className={'prod-periodo'}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p>Prod. no período</p>
                                            </Grid>
                                            <Grid item xs={12} style={{ alignItems: 'center' }}>
                                                <FormField
                                                    type={'date'}
                                                    error={''}
                                                    disabled={true}
                                                    ref='dataInicio'
                                                    id={'dataInicio'}
                                                    icon={'calendar.svg'}
                                                    inputValue={this.state.dataInicio}
                                                    changeValue={this.changeDataInicio}
                                                />

                                                <img src={process.env.PUBLIC_URL + '/assets/icons/arrowRight.svg'} alt='arrowIcon' />
                                                <FormField
                                                    type={'date'}
                                                    error={''}
                                                    disabled={true}
                                                    ref='dataFim'
                                                    id={'dataFim'}
                                                    icon={'calendar.svg'}
                                                    inputValue={this.state.dataFim}
                                                    changeValue={this.changeDataFim}
                                                />

                                            </Grid>
                                            <Grid item xs={12}>
                                                {(this.atendimento.produtoLinha.id === 3 || this.atendimento.produtoLinha.id === '3') ?
                                                    <div>
                                                        <h1 style={{ marginTop: '15px', fontSize: '30px' }}>{this.atendimento.quantidadeApropriadasMes} ensaios</h1>
                                                        <h1 style={{ fontSize: '30px' }}>{this.atendimento.quantidadeRelatorioMes} relatórios/laudos</h1>
                                                    </div>
                                                    :
                                                    <h1 style={{ marginTop: '15px' }}>{this.atendimento.quantidadeApropriadasMes}h</h1>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} xl={2} className={'prop-realizada'}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p>Prod. Realizada/Prevista</p>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginBottom: 40 }}>
                                                <ProgressBar idAtendimento={this.atendimento.id} total={this.atendimento.totalProducaoAtAll} producao={this.atendimento.numeroDeProducaoEstimada + this.atendimento.numeroDeRelatorioEstimado} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>

                                {!this.canSave() &&
                                    <Grid style={{ marginTop: '40px' }}>
                                        <GenericButton label={'Baixar histórico'} color={'darkBlue-outline'} subClass={'basic-button'} click={this.gerarRelatorioHistorico} />
                                    </Grid>
                                }

                                {!this.state.checkAtendimentoBMP && (<Grid item xs={12} style={{ marginTop: '50px', marginBottom: '40px' }}>
                                    <Table lines={this.atendimendoLineLote} titles={this.atendimentoTitlesLote} types={this.types} pagination={false} initialOrder={'1'}></Table>
                                </Grid>)}

                                <Table lines={this.atendimentoLines} titles={this.atendimentoTitles} types={this.types} initialOrder={'1'}></Table>
                                <Grid container>
                                    <Grid item xs={12} className={'rodape-apropriacao'}>
                                        <GenericButton color={'darkBlue-outline'} label={'Voltar'} subClass={'basic-button'} click={this.clearAtendimento} />
                                        <GenericButton color={'darkBlue'} label={'Salvar Produção'} subClass={'basic-button'}
                                            click={() => this.changeModal('modalProducao', true)}
                                            disabled={!this.canSave()} />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <SimpleModal
                                isOpen={this.modalProducao}
                                handleClose={() => this.changeModal('modalProducao', false)}
                                type={'info'}
                                width={'400px'}>
                                <div className={'modal modal-producao'}>
                                    <h2>Salvar produção</h2>
                                    <p>A apropriação de horas que você deseja fazer é referente ao dia:</p>

                                    <Grid style={{ width: '50%', marginLeft: '50%', marginRight: '40%' }}>
                                        <FormField type={'date'}
                                            ref='dataApropriacao'
                                            id={'dataApropriacao'}
                                            inputValue={this.state.dataAtual}
                                            changeValue={this.changeDataApropriar}
                                            icon={'calendar.svg'}
                                            min={this.firstDataApropriacaoProducao}
                                            max={this.lastDataApropriacaoProducao} />
                                    </Grid>

                                    <p style={{ color: '#D44E54', marginTop: '0px', marginBottom: '0px' }}>{this.state.dataInvalida}</p>

                                    <div className={'space'}>
                                        <img style={{ marginLeft: '20px' }} src={process.env.PUBLIC_URL + '/assets/icons/alert.svg'} alt='headerIcon' />
                                        <span style={{ marginLeft: '5px', width: '90%' }}>A apropriação de horas mensal pode ser realizada até o último dia do mês.</span>
                                    </div>

                                    <div className={'space'}>
                                        <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.changeModal('modalProducao', false)} />
                                        {this.state.dataAtual != '' ?
                                            <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button'} click={this.salvarProducao} />
                                            :
                                            <GenericButton color={'darkBlue'} label={'Salvar'} disabled subClass={'basic-button'} click={this.salvarProducao} />
                                        }
                                    </div>
                                </div>
                            </SimpleModal>

                            <SimpleModal
                                isOpen={this.modalHistorico}
                                handleClose={() => this.changeModal('modalHistorico', false)}
                                type={'info'}
                                width={'600px'}>
                                <div className={'modal modal-historico'}>
                                    <h2>Histórico de Produção</h2>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <p className={'font-blue'}>{this.tipoColPar}</p>
                                            <p>{this.nomeColPar}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <p className={'font-blue'}>Apropriações</p>
                                                <InputSelect
                                                    suggestions={this.anoList}
                                                    itemKey={'label'}
                                                    itemLabel={'label'}
                                                    id={'ano'}
                                                    getInput={this.changeInput}
                                                    initialSelectedItem={{ label: this.state.ano }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p >Apropriado por</p>
                                            {this.renderListProducao({ tipo: this.tipoColPar, nome: this.nomeColPar })}
                                        </Grid>
                                    </Grid>
                                    <div className={'space'}>
                                        <GenericButton color={'darkBlue-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.changeModal('modalHistorico', false)} />
                                        <GenericButton color={'darkBlue'} label={'Salvar Alterações'} subClass={'basic-button'} click={this.atualizarProducao} disabled={!this.validadeInput(this.state.producaoList)} />
                                    </div>
                                </div>
                            </SimpleModal>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={3} style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} className={'pesquisa'}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={12} sm={4} xl={4}>
                                                <FormField type={'text'} placeholder={'busque por ID, título, número ou cliente'}
                                                    changeValue={this.changeBusca}
                                                    pressEnter={this.aplicarFiltro}
                                                    inputValue={this.state.busca}
                                                    id={'busca'}
                                                    button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                                                        icon={process.env.PUBLIC_URL + 'search.svg'}
                                                        click={this.aplicarFiltro} />} />
                                            </Grid>
                                            <Grid item style={{ display: 'flex', width: '64%', alignItems: 'center' }}>
                                                <Grid className={'botao'}>
                                                    <GenericButton className={'buttonsModal'} color={'lightBlue'} label={'Busca avançada'} subClass={'basic-button busca-avancada'}
                                                        icon={process.env.PUBLIC_URL + 'filter.svg'}
                                                        click={() => this.changeModal('modalBusca', true)} />
                                                </Grid>

                                                <Grid className={'botao'} style={{ width: '24%', marginRight: '20%' }}>
                                                    <GenericButton label={'Baixar relatório'} color={'darkBlue-outline'} subClass={'basic-button'} click={this.gerarRelatorio} />
                                                </Grid>
                                                <Grid item xs={4} className={'total-atendimentos'}>
                                                    <label>Total de atendimentos:
                                                        <b>{this.state.totalAtendimentos}</b>
                                                    </label>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12}>
                                <Table lines={this.lines} titles={this.titles} initialOrder={'0'} action={this.setAtendimento} actionParamer={'8'} />

                                <EmptyList type={this.state.emptyList} seeAll={this.aplicarFiltro} />
                            </Grid>
                            <SimpleModal
                                isOpen={this.modalBusca}
                                handleClose={() => this.changeModal('modalBusca', false)}
                                type={'info'}
                                width={'400px'}>
                                <div className={'modal modal-busca-grupo'}>
                                    <h2>Busca avançada</h2>
                                    <div>
                                        <p className={'font-blue'}>Período</p>
                                    </div>
                                    <div className={'space'} style={{ alignItems: 'self-start' }}>
                                        <FormField
                                            type={'date'}
                                            error={this.errors.dataInicio}
                                            ref='dataInicio'
                                            id={'dataInicio'}
                                            inputValue={this.state.dataInicio}
                                            changeValue={this.changeData}
                                            icon={'calendar.svg'}
                                        />

                                        <img src={process.env.PUBLIC_URL + '/assets/icons/arrowRight.svg'} alt='arrowIcon' />

                                        <FormField
                                            type={'date'}
                                            error={this.errors.dataFim}
                                            ref='dataFim'
                                            id={'dataFim'}
                                            inputValue={this.state.dataFim}
                                            changeValue={this.changeData}
                                            icon={'calendar.svg'}
                                        />

                                    </div>
                                    <div className={'space'}>
                                        <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.changeModal('modalBusca', false)} />
                                        <GenericButton disabled={this.validateFiltro()} color={'darkBlue'} label={'Buscar'} subClass={'basic-button'} click={this.aplicarFiltro} />
                                    </div>
                                </div>
                            </SimpleModal>
                        </Grid>
                    }
                </Grid>
            </Page>
        );
    }
}