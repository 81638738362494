import React from "react";
import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const AlertProducaoBMP = ({ cliente, produtoRegional, horas, porte }) => {

    const mensagem = horas === undefined 
        ? `O cliente ${cliente.nome} possui porte ${porte} inválido para o produto ${produtoRegional?.produtoNacional?.descricao}.` 
        : `O cliente ${cliente.nome} possui porte ${porte} e deve ter ${horas} horas previstas no atendimento do produto ${produtoRegional?.produtoNacional?.descricao}.`;

    return (
        <Grid item xs={12}>
            <Alert severity="error">
                <AlertTitle>
                    <strong>Atenção:</strong> Atendimento do Programa Brasil Mais Produtivo
                </AlertTitle>
                {mensagem}
            </Alert>
        </Grid>
    );
};

export default AlertProducaoBMP;